.order-tracker-container{
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
    max-width: 650px;
}

.icon-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tracker-step-text{
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #979797;
    margin-bottom: 5px;
}

.tracker-line{
    flex:1;
    width: 50px;
    height: 1px;
    background-color: grey;
    margin: 20px 10px 0px 10px;
}