.home-content {
  margin: 3rem 0;
}

.section-container {
  margin-top: 6rem;
}

.section-text {
    display: grid;
    align-items: center;
    justify-content: center;
}

.header-font {
    font-size: 30px;
    font-weight: 900;
  }
  
  .p-large {
    font-size: 18px;
    font-weight: 300;
  }
  
  .home-content svg {
    position: absolute;
    width: 19vw;
    height: 590px;
   
}

.svg1 {
  transform: translateX(-100%);
    left: 1vw;
   
}

.svg2 {
  transform: translateX(100%);
  right: 1.4vw;
  
}

@media screen and (max-width: 1199px) {
  .home-content svg {
    display: none;
  }

  .circle img {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .section-container {
    margin-top: 3.5rem;
  }
}
  
  
  @media screen and (min-width: 480px) {
    .header-font {
      font-size: calc(30px + 6 * ((100vw - 480px) / 900));
    }
  }
   @media screen and (min-width: 1700px) {
    .header-font {
      font-size: 43px;
    }
  
    .p-large {
      font-size: 20px;
    }
  } 

  @media screen and (min-width: 825px) {
    .home-content .secion-inner {
      width: 92%;
      margin: auto;
    }

    .home-content .second-secion-inner {
      width: 95%;
      margin: auto;
    }
  }

  @media screen and (min-width: 992px) {
    .home-content .secion-inner {
      width: 80%;
      margin: auto;
    }

    .home-content .second-secion-inner {
      width: 85%;
      margin: auto;
    }
  }

  @media screen and (min-width: 1199px) {
    .home-content .secion-inner {
      width: 56%;
      margin: unset;
      margin-left: 5%;
    }

    .home-content .second-secion-inner {
      width: 100%;
      margin: auto;
    }

    .circle {
        width: 6rem;
        height: 6rem;
        box-shadow: 25px 20px 65px rgba(45, 52, 61, 0.21);
        background-color: #ffffff;
        position: absolute;
        border-radius: 50%;
       
      }

    .circle > div {
      width: 100%;
      height: 100%;
    }

    .circle img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

      .circle-left {
        top: -3rem;
        left: -2rem;
      }

      .circle-right {
        top: -3rem;
        right: -2rem;
      }
  }

  @media screen and (min-width: 1499px) {
    .custom-row {
      width: 100% !important;
      margin: auto !important;
      margin-top: 6rem !important;
    }

    .custom-second-row {
      width: 90% !important;
      margin: auto !important;
      margin-top: 6rem !important;
    }

    .home-content .secion-inner {
        width: 52%;
        margin: unset;
        margin-left: 18%;
      }

      .home-content svg {
        width: 20vw;
       height: 650px;
    }
    
  }
  
  .mt-4 {
    margin-top: 2rem;
  }