.ads-checkbox {
  margin-left: 25px;
}
.publish-button {
  margin-left: 27px;
}

/** Media query for screens below tablet screen*/
@media only screen and (max-width: 768px) {
  .create-advertisement-div {
    justify-content: unset !important;
  }
}
