html {
  --scrollbarBG: #efefef;
  --thumbBG: #000000;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

iframe {
  /* z-index: -9999 !important; */
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.announcementMessage {
  padding: 5px 10px;
  background-color: rgb(250, 204, 5, 0.5);
  border-radius: 15px;
  width: fit-content;
  max-width: 50%;
  word-break: break-all;
  margin: 5px 0px;
}
.send-field {
  bottom: 100px;
  position: fixed;
  width: 55.5%;
}

.messages-wrapper {
  overflow: scroll;
}
.messages-wrapper1 {
  max-height: 200px;
  overflow: scroll;
}

@media (min-width: 992px) and (min-width: 576px) {
  .user-main {
    margin-left: 30px !important;
  }
}
@media only screen and (max-width: 600px) {
  .send-field {
    bottom: 100px;
    position: fixed;
    width: 93%;
  }
  .send-field1 {
    bottom: 16px;
    position: fixed;
    width: 93%;
  }
}
@media (max-width: 1024px) {
  .messages-wrapper {
    max-height: 200px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .send-field {
    bottom: 100px;
    position: fixed;
    width: 38%;
  }
}
