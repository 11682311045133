.blog-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  padding: 4rem 5%;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  max-width: 1920px;
  margin: 0 auto;
}

.blog-item {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.blog-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  padding-top: 66.67%; /* 3:2 aspect ratio */
  overflow: hidden;
}

.post-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.blog-item:hover .post-image {
  transform: scale(1.05);
}

.post-content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.post-tag {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: #EEAC1A;
  color: white;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1rem;
  align-self: flex-start;
}

.post-heading {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
  line-height: 1.3;
  font-weight: 700;
  transition: color 0.3s ease;
}

.blog-item:hover .post-heading {
  color: #EEAC1A;
}

.post-text {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.read-more-btn {
  align-self: flex-start;
  padding: 0.75rem 1.5rem;
  background: transparent;
  color: #EEAC1A;
  border: 2px solid #EEAC1A;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.read-more-btn:hover {
  background: #EEAC1A;
  color: white;
  transform: translateX(5px);
}

/* Media Queries */
@media (max-width: 768px) {
  .blog-layout {
    grid-template-columns: 1fr;
    padding: 2rem 5%;
  }

  .post-content {
    padding: 1.5rem;
  }

  .post-heading {
    font-size: 1.25rem;
  }

  .post-text {
    font-size: 0.95rem;
  }
}

@media (min-width: 1440px) {
  .blog-layout {
    grid-template-columns: repeat(3, 1fr);
  }

  .post-heading {
    font-size: 1.75rem;
  }

  .post-text {
    font-size: 1.1rem;
  }
}
