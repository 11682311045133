.action-icon {
    cursor: pointer;
  }

.custom-tooltip {
    opacity: 1 !important;
    cursor: pointer;
  }
  
  .custom-tooltip .tooltip-inner {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 4px;
    padding: 6px 8px;
    font-size: 12px;
    max-width: 200px;
  }
  
  .custom-tooltip .arrow {
    display: none;
  }