.profile_image_seller {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #f0f0ff;
  padding: 5px;
}
.profile_image_seller:hover {
  -moz-box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  -webkit-box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
  cursor: pointer;
}

.seller-view-logo {
  height: 50px;
}
.user-dropdown {
  color: #ffc107 !important;
}
.user-dropdown-wrapper:hover .user-dropdown {
  color: white !important;
}

@media (max-width: 768px) {
  .profile_image_seller {
    display: none;
  }
  .seller-view-logo {
    height: 40px !important;
  }
}

@media (max-width: 480px) {
  .seller-view-logo {
    height: 30px !important;
  }
}
