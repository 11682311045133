.radio-alignA{
    cursor: pointer;
    margin-left: -1.35rem;
}

.radio-alignE{
    cursor: pointer;
    margin-left: -1.35rem;
}

.product-name {   
  float: left;
  font-family: Arial;
  margin: -8px 0;
  padding: 10px;
  width: 219px;
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  text-overflow: ellipsis;
  }
  .product-name:hover{
    overflow: visible;
  }
  .star{
    color:red;
  }