/* Grid Layout */
.media-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  /* Shared Card Styles */
  .event-card,
  .course-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    height: 400px; /* Set a fixed height for consistent card sizes */
    display: flex;
    flex-direction: column;
  }
  
  /* Thumbnail Styles */
  .media-thumbnail {
    height: 200px;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  /* Content Styles */
  .media-content {
    padding: 1.5rem;
    flex-grow: 1; /* Allow content to expand */
  }
  
  /* Title Styles */
  .media-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.5rem;
    color: #2c2c2c;
  }
  
  /* Description Styles */
  .media-description {
    font-size: 0.9rem;
    color: #1a1a1a;
    margin: 0.5rem 0;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent wrapping of the description text */
  }
  
  /* Event Specific Styles */
  .event-status {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
  }
  
  .status-indicator {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .upcoming {
    background-color: #2c2c2c;
    color: #EEAC1A;
  }
  
  .past {
    background-color: #1a1a1a;
    color: white;
  }
  
  .event-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.5));
  }

  .event-card:hover {
    opacity: 1;
    cursor: pointer;
  }


  
  .event-date-badge {
    position: absolute;
    left: 1rem;
    top: 1rem;
    background: white;
    padding: 0.5rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .event-month {
    display: block;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #1a1a1a;
  }
  
  .event-day {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    color: #2c2c2c;
  }
  
  .event-datetime {
    font-size: 0.9rem;
    color: #1a1a1a;
    margin: 0.5rem 0;
  }
  
  /* Course Specific Styles */
  .course-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .course-card:hover .course-overlay {
    opacity: 1;

  }

  .course-card:hover {
    opacity: 1;
    cursor: pointer;
  }
  
  .play-icon {
    font-size: 3rem;
    color: #EEAC1A;
    text-shadow: 0 2px 4px rgba(0,0,0,0.3);
  }
  
  .upload-date {
    font-size: 0.8rem;
    color: #1a1a1a;
    margin: 0.5rem 0;
  }
  
  /* Price and Button Styles */
  .event-price,
  .course-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #1a1a1a;
    
  }
  
  .price-tag {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c2c2c;
  }
  
  .join-button,
  .enroll-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
    
  }
  
  .join-button {
    background-color: #2c2c2c;
    color: #EEAC1A;
  }
  
  .join-button:hover {
    background-color: #1a1a1a;
  }
  
  .enroll-button {
    background-color: #1a1a1a;
    color: #EEAC1A;
  }
  
  .enroll-button:hover {
    background-color: #2c2c2c;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .media-grid {
      grid-template-columns: 1fr;
      padding: 1rem;
    }
  
    .media-content {
      padding: 1rem;
    }
  
    .event-date-badge {
      left: 0.5rem;
      top: 0.5rem;
    }
  }