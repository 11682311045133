* {
  font-family: Roboto;
}

/* Selected section of the navigation bar */

.current {
  color: black;
  background-color: white;
}

/* User profile cover picture */
.join_button_for_live {
  margin: 0px 15px;
  height: 40px;
  width: 120px;
  font-size: 16px;
  cursor: pointer;
}
.bodyImage {
  display: flex;
  height: 388px;
  width: 100%;
  flex-direction: column;
  justify-content: center; /* Vertical alignment */
}
.flex-box-1 {
  display: flex;
  flex-wrap: nowrap;
}
.userProfileBackground {
  width: 80px;
  height: 80px;
  border-radius: 4rem;
}
.user_profile_button {
  width: 50%;
  background-color: #000;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.active-tab {
  background-color: white;
  color: black;
  width: 50%;
  height: 50px;
}
.streamint_tab {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ofline_image {
  height: 150px;
  margin-top: 200px;
}
.userProfileBorder {
  width: 120px;
  height: 120px;
  border-radius: 84px;
  box-shadow: 0 3px 12px 0 rgba(0, 152, 81, 0.16);
  background-color: white;
  margin-left: 100px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_page {
  max-width: 1400px;
  width: 100%;
  padding: 3% 2% 5% 2%;
}

.heading {
  width: 255px;
  height: 51px;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -2.1px;
  text-align: left;
  color: white;
  margin-top: 100px;
}
.user-profile-detail-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 110px 0px;
}
.semiHeading {
  width: 100px;
  height: 10px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-top: -10%;
  margin-left: 10%;
}

.tab {
  overflow: hidden;
  background-color: black;
}

/* Style the buttons inside the tab */

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 20px;
  transition: 0.3s;
  font-size: 17px;
}

.tab .tablink-selected {
  background-color: white;
  text-align: center;
  width: 50%;
  height: 90px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.tab .tablink {
  color: white;
  text-align: center;
  height: 90px;
  width: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

/* Style the tab content */

.tabcontent {
  color: #000;
  background-color: white;
  padding-top: 5%;
  padding-bottom: 70px;
  width: 70%;
  margin: auto;
  border-radius: 29px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.topic {
  border-radius: 0px 5px 5px 0px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  font-weight: 900;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6%;
  align-items: center;
  position: relative;
}

.topic .topicText {
  color: #8d8d8d;
  font-size: 22px;
  margin-top: -20px;
}

.topic .liveText {
  margin-top: -20%;
  color: white;
}

/* Wrapping up the video and message section */

/* .video-section {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.video-section {
  video {
    width: 700px;
    height: 400px;
  }
} */
.live-stream-camra-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 20px;
}

.live-stream-camra-wrapper video {
  width: 100%;
  max-width: 1000px;
  height: auto;
}

.janus-video {
  width: auto;
  height: auto;
}

.janus-video-controls {
  height: 55px;
  background-color: #333;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 9px;
}

.message-section {
  min-width: 450px;
  width: 36%;
}

.message-section-1 {
  height: 400;
  margin-bottom: 20;
}

.message-section-1 > * {
  z-index: 99;
}

.edit-icon-1 {
  background: url(../User/images/other/edit-icon-1.png) no-repeat 0 0;
  width: 5px;
  height: 23px;
  margin-top: -20px;
  margin-left: 390px;
}

.reply-icon-1 {
  background: url(../User/images/other/reply-1.png) no-repeat 0 0;
  width: 25px;
  height: 18px;
  margin-left: 371px;
}

.message-section-closing-line {
  margin-top: 3%;
  height: 1px;
  background-color: #bfc9ca;
}

.janus-chat-container {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 0;
}

/* Item Slider  */

.flex-box-5 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
}

.carousel .slide {
  position: relative;
  height: 100px;
}

.slider-image {
  width: 100%;
  height: 100%;
  display: inline-block;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* items */

.flex-box-6 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.slide-item-image-1 {
  background-color: white;
  width: fit-content;
  height: 163px;
  box-shadow: 5px 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-right: 1%;
  margin-bottom: 1%;
}

.slider-item-num-1 {
  margin-right: 5%;
  /*: url(../User/images/other/item-no-1-1.png) no-repeat 0 0;*/
  width: 157px;
  height: 145px;
}

.slide-item-image-2 {
  background-color: white;
  width: fit-content;
  height: 163px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-right: 1%;
}

.slider-item-num-2 {
  background: url(../User/images/other/item-no-2-1.png) no-repeat 0 0;
  width: 156px;
  height: 145px;
}

.slide-item-image-3 {
  background-color: white;
  width: fit-content;
  height: 163px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-right: 1%;
}

.slider-desc-1 {
  background-color: white;
  color: black;
  margin-top: -15%;
  padding-left: 7%;
  width: 76%;
  padding-top: 2%;
  height: 25.5%;
}

.add-button {
  background: url(../User/images/other/add-button-1.png) no-repeat 0 0;
  width: 39.2px;
  height: 41px;
  margin-top: -26.5%;
  margin-left: 75%;
}

/* Arrows */

.carousel-control-prev {
  height: 59px;
  width: 59px;
  top: calc(55% - 40px);
  background-color: black;
}

.carousel-control-next {
  height: 59px;
  width: 59px;
  top: calc(55% - 40px);
  background-color: black;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 27px;
  height: 45px;
  background: no-repeat 50% / 100% 100%;
}

a:nth-child(3):hover {
  color: black;
  background-color: black;
}

a:nth-child(4):hover {
  color: black;
  background-color: black;
}

.carousel-control-prev-icon:after {
  content: "←";
  color: white;
}

.carousel-control-next-icon:after {
  content: "→";
  color: white;
}

/* About Us Section*/

.about-section-1 {
  margin-right: 5%;
  margin-left: 5%;
}

.user-info {
  font-size: 15px;
  width: 6%;
  font-weight: normal;
  text-align: left;
  color: black;
  vertical-align: top;
  padding-left: 2%;
}

.discription {
  width: 10%;
  color: #bfc9ca;
}

.divide-line {
  display: block;
  border: none;
  color: white;
  height: 2px;
  background: #f6f6f6;
}

.secured-image {
  background: url(../User/images/other/lock-image-1.png) no-repeat 0 center;
  width: 167px;
  height: 18px;
  padding-left: 45px;
  color: #bfc9ca;
}

.scale-down-product-preview {
  width: 145px;
  transform: scale(0.7);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .live-stream-camra-wrapper {
    video {
      width: 300px !important;
      height: 300px !important;
    }
  }
  .userProfileBorder {
    width: 100px;
    height: 100px;
    margin-left: 100px;
    margin-top: 100px;
  }
  .userProfileBackground {
    width: 80px;
    height: 80px;
  }
  .heading {
    margin-top: 140px;
  }
  .tabcontent {
    width: 100%;
  }
  .user_profile_button {
    width: 100%;
    padding: 10px;
  }
  .active-tab {
    background-color: white;
    width: 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .userProfileBorder {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-top: 100px;
  }
  .user-profile-detail-wrapper {
    display: flex;
    flex-direction: column;
  }
  .userProfileBackground {
    width: 50px;
    height: 50px;
  }
  .tabcontent {
    margin-top: 150px;
  }
  .heading {
    margin-top: 0px;
    text-align: center;
  }
}
