
  /* payment page css */

  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
 
 
a {
  text-decoration:none;
  color:#333;
}
.title {
  padding:10px;
  text-align:center;
}

.title h1 {
  font-size:16px;
  color:#333;
  font-weight:normal;

}

.item {
  border-top:solid 1.5px #f1f1f1;
  padding:0px 20px;
  background-color:#fff;
  transition: background-color ease 240ms;
  font-size:13px;
  padding:18px 20px 15px 20px;

}

.item:hover {
  background-color:#f6f6f6;
}
.item-image {
  width: 40px;
  height: 30px;
  display:inline-block;
  margin-right:10px;
  vertical-align:middle;
}
.item-text {
  display:inline-block;
  padding-bottom:10px;
}
.item-selected {
  background-color:#f6f6f6;
  position:relative;
}

.button {
  padding:20px;
  background-color:#598BDD;
  border-radius: 0px 0px 4px 4px;
  color:#fff;
  text-align:center;
  transition: background-color ease 240ms;
  font-size:13px;
}

.button:hover {
  /* background-color:#4E7CC7;  
  transform: translateY(1px); */
}

.check {
  position:absolute;
  right:20px;
  top:20px; 
}
.check-box {
  position:absolute;
  right:20px;
  top:155px; 
}
.arrow {
  display:inline-block;
  vertical-align:bottom;
}

.dots-container {
  display:block;
  margin:10px auto;
}

.dots {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin: 0 0.125em;
    border-radius: 50%;
    background-color: #DEDEDE;
}

.dot-active {
  background-color:#AAAAAA;
}

.paypal-image {
  width: 40px;
  height: 30px;
}

.row-alignment{
    margin-top: 10%;
}

/* custom css */

.pad-top-2 {
  padding: 2%;
}

.input-border {
  border: 1px solid #c8ced2 !important;
}

.mar-right-4 {
  margin-right: 4%;
}


/* spinner css */
.spinner-wave {
  margin: 10px auto;
  width: 100px;
  height: 50px;
  text-align: center;
}
.spinner-wave > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: wave 1.6s infinite ease-in-out;
  -moz-animation: wave 1.6s infinite ease-in-out;
  -o-animation: wave 1.6s infinite ease-in-out;
  -ms-animation: wave 1.6s infinite ease-in-out;
  animation: wave 1.6s infinite ease-in-out;
}

.spinner-wave div:nth-child(2) {
  -webkit-animation-delay: -1.4s;
  -moz-animation-delay: -1.4s;
-ms-animation-delay: -1.4s;
-o-animation-delay: -1.4s;
  animation-delay: -1.4s;
}

.spinner-wave div:nth-child(3) {
  -webkit-animation-delay: -1.2s;
  -moz-animation-delay: -1.2s;
-ms-animation-delay: -1.2s;
-o-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.spinner-wave div:nth-child(4) {
  -webkit-animation-delay: -1.0s;
  -moz-animation-delay: -1.0s;
-ms-animation-delay: -1.0s;
-o-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner-wave div:nth-child(5) {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
-ms-animation-delay: -0.8s;
-o-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes wave {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}
@-moz-keyframes wave {
  0%, 40%, 100% { transform: scaleY(0.4); }
  20% { transform: scaleY(1.0); }
}
@-o-keyframes wave {
  0%, 40%, 100% { transform: scaleY(0.4); }
  20% { transform: scaleY(1.0); }
}
@-ms-keyframes wave {
  0%, 40%, 100% { transform: scaleY(0.4); }
  20% { transform: scaleY(1.0); }
}
@keyframes wave {
  0%, 40%, 100% { transform: scaleY(0.4); }
  20% { transform: scaleY(1.0); }
}

.payment-ui {
  margin-top: 0.5rem;
}
/* end of spinner css */

@media only screen and (max-width:768px){
  .row-alignment{
    margin-top: 17%;
}
}

@media only screen and (max-width:425px){
  .payment-button{
    margin-top: 1rem;
    }
}
