/* .account-detail{
    display: flex;
    justify-content: center;
    flex-direction: row;
} */
.main-row {
  border-radius: 5px;
  margin-top: 110px;
  padding: 20px 0px;
}
.main-row-margin-top {
  margin-top: 110px;
}
.edit-form {
  padding-bottom: 40px;
}
.file-input {
  border-radius: 50%;
}
.profilepic {
  height: 100px;
  width: 100px;
  margin-left: 300px;
  border-radius: 50%;
  border: 0.5px solid black;
}
.card-wrapper {
  margin: 20px 30px;
  width: 70%;
}
.user-text-heading {
  font-size: 16px;
  font-weight: bold;
}
.user-text-wrapper {
  font-size: 16px;
  font-weight: 500;
  margin-top: -10px;
}
.edit-button {
  background-color: #f6c803;
  width: 70%;
  font-size: 18px !important;
}
.user-profile-image {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}
.addicon {
  margin-left: -6px;
}
