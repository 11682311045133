.pimage:hover{
    cursor: pointer;
    transform: scale(1.3, 1.3); 
    opacity: 1;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
}

.imageB:hover{
    cursor: pointer;
    border:2px solid #fbc108;
}

.ellipsis {
    overflow: hidden;
    /* height: 200px; */
    line-height: 25px;
    margin: 20px;
    /* border: 5px solid #AAA; */
 }

.ellipsis:before {
    content:"";
    float: left;
    width: 5px; height: 200px; }

.ellipsis > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px; }        

.ellipsis:after {
    content: "\02026";  

    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    float: right; position: relative;
    top: -25px; left: 100%; 
    width: 3em; margin-left: -3em;
    padding-right: 5px;

    text-align: right;

    background: -webkit-gradient(linear, left top, right top,
        from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white));
    background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);           
    background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
 }

 .qty-button{
    border-radius: 50%;
    background-color:white;
    border-color:#a5aeb7;
  }
  
  .qty-btn{
    background-color:white;
  }

  .detail-price{
    color:red;
  }
  
  .offers{
      color:darkolivegreen;
  }

  .offers-manage{
      margin-top: -17px;
  }

  .product-price{
      margin-left: 15px;
  }