.financial-coaching-process {
  background-color: white;
  padding: 2rem;
  text-align: center;
}

.fcp-container {
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

.steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.step {
  flex: 1 1 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f3f4f6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease;
  height: auto;
  min-height: 320px;
}

.step:hover {
  transform: translateY(-10px);
}

.icon-container {
  background-color: #EEAC1A;
  color: white;
  border-radius: 50%;
  padding: 1rem;
  margin-bottom: 1rem;
}

.icon-container svg {
  width: 2rem;
  height: 2rem;
}

.step h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  color: #111827;
  background-color: #f3f4f6;
}

.step p {
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  
}

/* Small devices (mobiles) */
@media (max-width: 767px) {
  .financial-coaching-process {
    padding: 1rem;
  }

  .steps-container {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .step {
    max-width: 100%;
    padding: 1rem;
  }

  .step h3 {
    font-size: 1.25rem;
  }

  .step p {
    font-size: 0.875rem;
    -webkit-line-clamp: 3;
  }

  .icon-container svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}

/* Tablets (medium devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .financial-coaching-process {
    padding: 1.5rem;
  }

  .step {
    flex: 1 1 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
    padding: 1.25rem;
  }

  .step h3 {
    font-size: 1.4rem;
  }

  .step p {
    font-size: 0.95rem;
  }

  .icon-container svg {
    width: 2rem;
    height: 2rem;
  }
}

/* Laptops (large devices) */
@media (min-width: 1024px) {
  .financial-coaching-process {
    padding: 2rem;
  }

  .steps-container {
    flex-wrap: nowrap;
    gap: 1.5rem;
  }

  .step {
    flex: 1;
    min-width: 0;
    /* Allows flex items to shrink below content size */
    max-width: none;
    padding: 1.5rem;
  }

  .step h3 {
    font-size: 1.5rem;
  }

  .step p {
    font-size: 1rem;
  }

  .icon-container svg {
    width: 2.25rem;
    height: 2.25rem;
  }
}

/* Large screens (desktops) */
@media (min-width: 1200px) {
  .financial-coaching-process {
    padding: 3rem;
  }

  .steps-container {
    gap: 2rem;
  }

  .step {
    padding: 1.75rem;
  }

  .step h3 {
    font-size: 1.6rem;
  }

  .step p {
    font-size: 1.05rem;
  }

  .icon-container svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}