.footer-contents {
  line-height: 0.5;
  margin-left: -20px;
}
.seller-login {
  cursor: pointer;
}

hr {
  color: white;
}
.facebook-icon {
  color: white !important;
}
.goog-te-gadget .goog-te-combo {
  margin: 0;
  padding: 0.5rem;
  border-radius: 3px;
}

.footer-part {
  margin-top: 13%;
}

.cms-category-name {
  font-size: 15px;
  font-weight: 600;
}

.link {
  color: #20a8d8;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.column-container {
  margin-top: 80px;
}
.social-container {
  align-items: self-start;
  display: flex;
  flex-direction: column;
}

.social-link {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 15px;
}
.social-link > a {
  color: #20a8d8;
}
.social-icon {
  margin-right: 5px;
}
