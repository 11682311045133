.main-div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-box {
  width: 30%;
  max-width: 400px;
  height: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 40px 20px;
}

.card-box h5 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.input-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-container label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.input-box {
  width: 100%;
  outline: none;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid lightgray;
  margin-bottom: 4px;
}
.update-password-button {
  width: 100%;
  margin-top: 10px;
  background-color: #eeac1a;
  cursor: pointer;
}
.update-password-button:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
@media (max-width: 480px) {
  .card-box {
    margin-top: 70px;
  }
}

@media (max-width: 1100px) {
  .card-box {
    width: 80%;
  }
}
