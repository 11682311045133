.header-fixed .app-header {
  position: fixed;
  z-index: 1020 !important;
  width: 100% !important;
  height: 75px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #000000;
}
.icon-bell {
  font-size: 20px !important;
  color: #fbc108 !important;
}

.userName {
  color: white !important;
  font-size: "13px";
}
.picture-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #f0f0ff;
  padding: 5px;
}
.picture-image:hover {
  -moz-box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  -webkit-box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
  cursor: pointer;
}
.header-menu-style {
  font-size: 14px;
  margin-right: 30px;
  margin-top: 3px;
}
/* .header-menu-margin {
    margin-top: 8px !important;
} */
.shopping-cart-css {
  /* margin-left: 16% !important; */
  margin-top: -9px !important;
}
.drop-content {
  top: 13px !important;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(255, 255, 255);

  width: 96px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.nav-cart-icon {
  width: 32px;
  margin-right: 50px;
  background-image: url("../assets/img/cart-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  margin-top: -15px;
}

.nav-profile-icon {
  width: 42px !important;
  height: 42px !important;
  margin-right: 50px;
  background-image: url("../assets/img/nav-profile.svg");
}

.arrow-down {
  width: 2px;
  height: 2px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
  margin-top: 6px;
  margin-left: 3px;
}

.profile-nav-link {
  color: rgb(255, 255, 255);

  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.nav-cart-icon:hover {
  cursor: pointer;
}

.closed {
  visibility: initial !important;
  opacity: 100 !important;
}

.user-wallat-amont {
  color: #ffffff;
  font-weight: 900;
  font-size: small;
}
.user-wallat {
  color: #ffffff;
}

.wallet-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 15px;
  margin-left: 18px;
}

@media (max-width: 768px) {
  .wallet-holder {
    display: none;
  }
}
@media (max-width: 1200px) {
  .wallet-holder {
    margin-left: 20px;
  }
  .userName {
    white-space: nowrap;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
