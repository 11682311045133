.processSection {
  margin: 4rem auto;
  padding: 2rem;
  background: linear-gradient(to bottom, #f9fafb, #ffffff);
  max-width: 1400px;
}

.processHeader {
  text-align: center;
  margin-bottom: 3rem;
}

.processHeading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #EEAC1A;
  margin-bottom: 1rem;
}

.processUnderline {
  width: 80px;
  height: 4px;
  background: #EEAC1A;
  margin: 0 auto;
  border-radius: 2px;
}

.processContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.processStep {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
}

.stepNumber {
  width: 40px;
  height: 40px;
  background-color: #EEAC1A;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  flex-shrink: 0;
}

.stepContent {
  flex-grow: 1;
}

.processStep h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #EEAC1A;
  margin-bottom: 1rem;
  transition: color 0.3s ease;
}

.processStep p {
  font-size: 1.1rem;
  color: #6b7280;
  line-height: 1.6;
  transition: color 0.3s ease;
}

.processStep:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(238, 172, 26, 0.15);
}

.processStep.active {
  background: linear-gradient(135deg, #EEAC1A, #F7C55B);
  transform: scale(1.02);
}

.processStep.active .stepNumber {
  background-color: white;
  color: #EEAC1A;
}

.processStep.active h3,
.processStep.active p {
  color: white;
}

.activeIndicator {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  background-color: #EEAC1A;
  border: 3px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Hover effects */
.processStep:hover:not(.active) .stepNumber {
  transform: scale(1.1);
}

/* Animation keyframes */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Media Queries */
@media (max-width: 768px) {
  .processSection {
    padding: 1rem;
  }

  .processHeading {
    font-size: 2rem;
  }

  .processContainer {
    grid-template-columns: 1fr;
  }

  .processStep {
    padding: 1.5rem;
  }
}

/* Ensure smooth transitions */
* {
  transition: all 0.3s ease;
}