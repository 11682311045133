.TestimonialCard {
  display: flex;
    flex-direction: column;
    gap: 2rem;
  width: 100vw;
}

.Testimonial-heading{
    text-align: center;
      font-weight: bold;
      padding: 1.5rem 0;
      margin-top: 2rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      background-color: #f3f4f6;

}

.TestimonialCard-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 0 auto;
}

.TestimonialCard-heading {
  font-size: 1.5rem;
  font-weight: 700;

}

.TestimonialCard-text {
  font-size: 1.1rem;
  color: #4b5563;
  line-height: 1.6;
}

.TestimonialCard-author {
  font-size: 1rem;
  font-weight: bold;
  color: #6b7280;
  text-align: right;
}

/* Responsive styles */
@media (max-width: 767px) {
  .TestimonialCard-container {
    padding: 1rem;
    width: 90%;
  }

  .TestimonialCard-heading {
    font-size: 1.2rem;
  }

  .TestimonialCard-text {
    font-size: 0.9rem;
  }

  .TestimonialCard-author {
    font-size: 0.8rem;
  }
}