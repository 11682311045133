.onerror {
  color: red;
}

.image-padding {
  padding-right: 2%;
  cursor: pointer;
}

.imag:hover .middle {
  opacity: 1;
}

.container {
  position: relative;
  width: 100%;
  display: inline-block;
}
.text-eara-product-story {
  width: 100%;

  border-radius: 5px;
  padding: 8px;
  height: 70px;
  border: 1px solid lightgray;
  outline: none;
  font-weight: 400;
  resize: none;
  color: gray;
}

.image {
  width: 100%;
  height: auto;
  display: inline-block;
  flex-wrap: wrap;
  padding: 3px 4px;
}

.flex-images {
  gap: 25px 0;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  cursor: pointer;
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.category-padding {
  padding-left: 5%;
  padding-right: 5%;
}

.close {
  position: absolute;
  right: 40px !important;
  top: 4px;
  width: 12px;
  height: 11px;
  opacity: 1 !important;
  background-color: white;
}

.close:before,
.close:after {
  position: absolute;
  left: 5px;
  content: " ";
  height: 10px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 230px;
  height: 100px;
  margin-bottom: 10px;
}
.public-DraftStyleDefault-ltr {
  /* direction: ltr; */
  /* text-align: left; */
  border: 1px solid #e4e7ea;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
thead {
  text-align: center;
}

.public-DraftStyleDefault-block {
  margin: 1em 0;
  border: 2px solid #e4e7ea;
  border-radius: 3px;
  height: 80px;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 3px;
  border: 7px solid #e4e7ea;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background: white;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-error {
  border: 2px solid red;
  border-radius: 5px;
}
