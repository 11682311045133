.cms-table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.cms-table {
  width: 100%;
  margin-bottom: 30px;
}

.cms-thead {
  background-color: rgb(21, 18, 18);
  height: 40px;
  color: #ffff;
}
.cms-tbody {
  border: 1px solid lightgray;
}
.cms-tbody td {
  border: 1px solid lightgray;
  padding: 8px;
}

@media (max-width: 575) {
  .cms-table-container {
    overflow-x: auto;
  }

  .cms-table {
    width: 100%;
  }
}
