.radio-alignA {
  cursor: pointer;
  margin-left: -1.35rem;
}

.radio-alignE {
  cursor: pointer;
  margin-left: -1.35rem;
}

.product-name {
  float: left;
  font-family: Arial;
  margin: -8px 0;
  padding: 10px;
  width: 219px;
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  text-overflow: ellipsis;
}
.product-name:hover {
  overflow: visible;
}
.star {
  color: red;
}

.image-padding {
  padding-right: 2%;
  cursor: pointer;
}

.image {
  width: 100%;
  height: auto;
  display: inline-block;
  flex-wrap: wrap;
  padding: 3px 4px;
}

.close {
  position: absolute;
  right: 30px;
  top: 4px;
  width: 12px;
  height: 11px;
  opacity: 1 !important;
  background-color: rgb(255, 255, 255);
}

.close-img {
  position: absolute;
  right: 0px;
  top: 1px;
  width: 12px;
  height: 11px;
  opacity: 1 !important;
  background-color: rgb(255, 255, 255);
}

.close-img:before,
.close-img:after {
  position: absolute;
  left: 5px;
  content: " ";
  height: 10px;
  width: 2px;
  background-color: #333;
}
.close-img:before {
  transform: rotate(45deg);
}
.close-img:after {
  transform: rotate(-45deg);
}
.close:before,
.close:after {
  position: absolute;
  left: 5px;
  content: " ";
  height: 10px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.btn-full {
  width: 100%;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
