.myorders-background {
    background-color: #ffffff;
    color: #000;
    margin: 6.6% 4.5% 6.6% 4.5%;
    min-width: 160px;
    padding-bottom: 5.3%;
}

.myorders-heading {
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 24px;
    padding: 2.7% 10% 0% 10%;
}

.myorders-divideline { 
    display:block;
    border:none;
    color:white;
    height:2px;
    background:#f6f6f6;
    margin: 2% 8% 2.5% 8%;
}

.myorders-containers {
    background-color: #f8f8f8;
    margin: 1% 10% 1% 10%;
    min-width: 160px;
}

.full-container {
    display: flex;
    flex-wrap: wrap;
    padding:  2.5% 4.4% 1.5% 4.4%;
    justify-content: space-between;
}

.myorders-item-image {
    width: 173px;
    height: 173px;
    margin-right: 48px;
    margin-bottom: 1.4%;
}

.myorders-item-name {
    font-size: 18px;
    font-weight: bold;
}

.myorders-item-brand {
    font-size: 12px;
    color: #BFC9CA;
}

.myorders-item-country {
    font-size: 16px;
    padding-top: 1%;
}

.myorders-item-quantity {
    font-size: 18px;
    padding-top: 1%;
}

.myorders-item-price {
    font-weight: bold;
    padding-top: 3%;
}

.myorders-total-price {
    align-items: center;
    display: flex;
    margin-top: -34px;
    margin-left: 50%;
}

.myorders-currency {
    font-weight: normal;
    font-size: 12px;
}

.myorders-total-price-number {
    font-weight: bold;
    font-size: 28px;
    padding-left: 20px;
    margin-bottom: 0%;
}

.myorders-progress{
    width: 35%;
    height: 173px;
    min-width: 350px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.myorders-progress-step{
    display: flex;
    flex-direction: column;
}

.myorders-progress-line {
    -ms-flex: 1 1;
    flex: 1 1;
    height: 1px;
    background-color: grey;
    margin: 20px 10px 0;
}

.myorders-progress-status-text {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #979797;
    margin-bottom: 10px;
}

.myorders-progress-view-reciept{
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: underline;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #004cff;
    cursor: pointer;
}
