.main-content {
  padding: 0px 50px;
}
.header-content {
  margin-top: 100px;
}



@media only screen and (max-width: 425px) {
  .main-content {
    padding: 10px 0px !important;
  }
}