section {
  margin: 3rem 0;
}

.services .service-section-container {
  margin-top: 6rem;
}

.services .service-section-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.services .flow-btn-right {
  position: absolute;
  bottom: 3.5rem;
  width: 12rem;
  right: 31rem;
}

.services .flow-btn-left {
  position: absolute;
  bottom: 3.5rem;
  width: 12rem;
  left: 31rem;
}

.services svg {
  width: 25px;
}

.services .black-bg-btn {
  background-color: #000000;
  color: #fff;
  font-weight: normal;
  border-radius: unset;
  box-shadow: none;
}

.services .black-bg-btn:hover {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .services .flow-btn-left {
    left: 25rem;
  }

  .services .flow-btn-right {
    right: 25rem;
  }
}

@media screen and (max-width: 992px) {
  .services .flow-btn-left {
    left: unset;
    right: 1rem;
    bottom: 0;
  }

  .services .flow-btn-right {
    right: unset;
    left: 1rem;
    bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .services .service-section-container {
    margin-top: 3.5rem;
  }

  .services .flow-btn-left,
  .services .flow-btn-right {
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 40px;
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .services .service-section-container {
    margin-top: 3.5rem;
  }

  .services .flow-btn-left,
  .services .flow-btn-right {
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 50px;
    width: 90%;
  }
}

@media screen and (min-width: 1199px) {
  .services .service-section-inner {
    width: 79%;
  }
}

.button-text {
  font-size: 12px;
}

@media screen and (min-width: 1700px) {
  .button-text {
    font-size: 14px;
  }
}
