.topBorderOnly {
    border-top: 1px solid black;
}

.bottomBorderOnly {
    border-bottom: 1px solid black;
}

.shipmentValidation {
    color : red;
}

h4{
	font-weight: 500;
	line-height: 1.1;
}

.dialog-box{
    width: 70%;
    min-height: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -190px;
    margin-left: -30%;
    background-color: white;
    border-radius: 2px;
    z-index: 100;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 4px, rgba(0, 0, 0, 0.28) 0px 4px 8px;
    color: black;
    height: 460px;
    overflow: scroll;
    transform: scale(1);
    transition-property: transform;
    transition-timing-function: ease;
    transition-duration: 1000ms;
    background-color: white;
}