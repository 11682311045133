.cards{
    margin-bottom: 5%;
}

.payment{
    margin-left: 12px;
}

.payment-button{
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8;
}

.validations{
    color: red;
    margin-top: 0%;
    /* margin-left: 27%; */
}