:root {
  --primary-color: #EEAC1A;
  --primary-dark: #2c2c2c;
  --secondary-bg: #1a1a1a;
  --text-primary: #ffffff;
  --text-secondary: #c0c0c0;
  --card-shadow: 0 15px 35px -10px rgba(238, 172, 26, 0.2);
  --transition-base: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.event-details-page {
  padding: 2rem;
  background: #f3f4f6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
}

.event-details-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  background: rgba(44, 44, 44, 0.8);
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  overflow: hidden;
  margin-bottom: 2rem;
  transition: var(--transition-base);
  position: relative;
  margin-top: 5%;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(238, 172, 26, 0.1);
  margin-top: 0px;
}

.event-details-container:hover {
  transform: translateY(-12px);
  box-shadow: 0 25px 50px -15px rgba(238, 172, 26, 0.3);
}

.event-details-thumbnail {
  width: 50%;
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 600px;
  position: sticky;
  top: 0;
  transition: transform 0.4s ease;
  filter: brightness(0.9);
}

.event-details-thumbnail:hover {
  transform: scale(1.03);
  filter: brightness(1);
}

.event-category-badge {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: rgba(238, 172, 26, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: 600;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.event-details-content {
  width: 50%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-primary);
}

.event-details-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  border-bottom: 3px solid var(--primary-color);
  padding-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.event-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
  background: rgba(238, 172, 26, 0.1);
  padding: 1.5rem;
  border-radius: 12px;
  border: 1px solid rgba(238, 172, 26, 0.2);
}

.highlight-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
}

.highlight-item:hover {
  background-color: rgba(238, 172, 26, 0.2);
}

.highlight-icon {
  font-size: 2rem;
  color: var(--primary-color);
}

.highlight-content {
  display: flex;
  flex-direction: column;
}

.highlight-label {
  font-size: 0.8rem;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.highlight-value {
  font-size: 1.1rem;
  color: var(--text-primary);
  font-weight: 600;
}

.section-title {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-weight: 600;
  position: relative;
  padding-left: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.section-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 80%;
  background-color: var(--primary-color);
}

.event-details-description {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.7;
  margin-bottom: 2rem;
}

.event-buy-now-btn {
  width: 100%;
  padding: 1rem;
  background: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-base);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-top: auto;
  box-shadow: 0 8px 25px rgba(238, 172, 26, 0.4);
}

.event-buy-now-btn:hover {
  background: var(--text-primary);
  color: var(--primary-color);
  transform: translateY(-4px);
  box-shadow: 0 12px 30px rgba(238, 172, 26, 0.5);
}

@media (max-width: 1023px) {
  .event-details-container {
    flex-direction: column-reverse;
    margin-top: 5%;
    margin-top: 100px;
  }
  
  .event-details-thumbnail,
  .event-details-content {
    width: 100%;
  }
  
  .event-details-thumbnail {
    min-height: 400px;
  }
  .suggested-price-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
  }
  
  .suggested-price-label {
    font-size: 0.8rem;
    color: var(--text-primary);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0.5rem;
  }
  
  .suggested-price-input {
    width: 100%;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(238, 172, 26, 0.3);
    border-radius: 6px;
    color: var(--text-primary);
    font-size: 1rem;
    transition: var(--transition-base);
  }
  
  .suggested-price-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(238, 172, 26, 0.2);
  }
  
  .price-rating-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .event-buy-now-btn:disabled {
    background-color: #666666;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
}

@media (max-width: 768px) {
  .event-details-container {

    margin-top: 20px;
  }

  .event-details-page {
    padding: 1rem;
  }
  
  .event-details-content {
    padding: 2rem 1.5rem;
  }
  
  .event-details-title {
    font-size: 2rem;
  }

  .suggested-price-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
  }
  
  .suggested-price-label {
    font-size: 0.8rem;
    color: var(--text-primary);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0.5rem;
  }
  
  .suggested-price-input {
    width: 100%;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(238, 172, 26, 0.3);
    border-radius: 6px;
    color: var(--text-primary);
    font-size: 1rem;
    transition: var(--transition-base);
  }
  
  .suggested-price-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(238, 172, 26, 0.2);
  }
  
  .price-rating-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .event-buy-now-btn:disabled {
    background-color: #666666;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
}



@media (min-width: 1024px) {

  .event-highlights {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
    background: rgba(238, 172, 26, 0.1);
    padding: 1.5rem;
    border-radius: 12px;
    border: 1px solid rgba(238, 172, 26, 0.2);
  }
  
  
}



.suggested-price-section {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.suggested-price-label {
  font-size: 0.8rem;
  color: var(--text-primary);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0.5rem;
}

.suggested-price-input {
  width: 100%;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(238, 172, 26, 0.3);
  border-radius: 6px;
  color: var(--text-primary);
  font-size: 1rem;
  transition: var(--transition-base);
}

.price-button {
  width: 20%;
  background: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 0.7rem;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-base);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-left: 1rem;
  box-shadow: 0 8px 25px rgba(238, 172, 26, 0.4);
  
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}


.suggested-price-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(238, 172, 26, 0.2);
}

.suggested-price-input::placeholder {
  color: white; 
}





.price-rating-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-buy-now-btn:disabled {
  background-color: #666666;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}