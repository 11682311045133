
.slick-slider {
max-width: 475px;
width: 85%;
position: relative;
}

.slick-prev,  .slick-next {
  height: 40px;
  width: 44px;
  border-radius: 4px;
  background: transparent;
}

.slick-next{
  /* right: 485px; */
}

.slick-dots li button:before{
  font-size: 20px;
}

