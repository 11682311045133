.bookingdate-page {
    color: #000;
    background-color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    min-width: 160px;
    border: 0.5px solid #707070;
    border-radius: 5px;
}

.all-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.startdate-container {
    min-width: 50px;
    width: 40%;
}

.enddate-container {
    min-width: 50px;
    width: 40%;
}

.startdate-setdate {
    padding-top: 3%;
    width: 100%;
}

.enddate-setdate {
    padding-top: 3%;
    width: 100%;
    
}

.enddate-setdate input{
    width: 100%;
}

.startdate-setdate input{
    width: 100%;
}

.date-divide-line {
    border-left: 1px solid #BFC9CA;
    height: 30px;
}