.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
}

@media (min-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.contact-info,
.contact-form-section {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-info-header {
  margin-bottom: 2rem;
  /* text-align: center; */
}

.contact-info-header h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #EEAC1A;
  margin-bottom: 1rem;
}

.contact-info-header p {
  color: #666;
  line-height: 1.6;
}

.contact-items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contact-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.contact-item-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #EEAC1A;
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.contact-item-content h1 {
  font-weight: 700;
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.contact-item-content p {
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.contact-form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form-section h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #EEAC1A;
  margin-bottom: 1.5rem;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 600px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: #333;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  background-color: white;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-color: #EEAC1A;
  box-shadow: 0 0 0 3px rgba(238, 172, 26, 0.2);
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.form-group-select {
  position: relative;
}

.form-group-select::after {
  content: '▼';
  font-size: 0.8rem;
  color: #666;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.submit-button {
  width: 100%;
  background-color: #EEAC1A;
  color: white;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #d48f18;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-message {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #f0fdf4;
  color: #166534;
  border-radius: 0.5rem;
  text-align: center;
  font-weight: 500;
}