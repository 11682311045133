

li{
    color: #f6c803;
    text-decoration: none;
}
li:hover{
    color: black;
    cursor: pointer;
    text-decoration: none;
}