.summary-container {
    width: 100%;
    min-height: 400px;
    background-color: #ffffff;
    padding-top: 45px;
}

.summary-title-text {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #00ff6f;
}

.summary-description-text {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #b4b4b4;
    margin-top: 10px;
    margin-bottom: 30px;
}

.summary-entry-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    column-gap: 12px;
}

.summary-entry-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.summary-entry-value {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #9c9c9c;
}

.summary-separator {
    width: 100%;
    height: 1px;
    background-color: #000000;
    margin-top: 10px;
    margin-bottom: 30px;
}

.summary-total-text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.product-summart-title-text {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #0a0a0a;
    margin-bottom: 40px;
}

.product-summary-entry-container {
    display: flex;
    margin-bottom: 20px;
}

.product-summary-entry-image {
    height: 140px;
    width: 140px;
    min-width: 140px;
    margin-right: 25px;
    overflow: hidden;
    border: 1px solid #000000;
    object-fit: fill;
}

.product-summary-entry-title {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-top: 7px;
}

.product-summary-entry-quantity {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin: 10px 0px 0px 0px;
}

.product-summary-container {
    width: 100%;
    min-height: 320px;
    max-height: 480px;
    background-color: #ffffff;
    padding-top: 45px;
    overflow-y: auto;
}
