.payment-method-wrapper {
  margin-top: 80px;
}
.payment-method {
  background-color: lightgray;
  height: 90px;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-method h3 {
  font-size: 22px;
  font-weight: 600;
}

.payment-mehod-status {
  background-color: #f6c803;
  height: 40px;
  padding: 8px 15px;
  border-radius: 10px;
  color: black;
  font-size: 12px;
}
.payment-method-tagline {
  font-size: 16px;
  font-weight: 500;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .payment-method {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .payment-method {
    width: calc(100% - 10px);
  }
}
