/* Base header styles */
.pf-header-container {
  background: #111;
  color: white;
  z-index: 999999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pf-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1rem 2rem;
  background: #111;
  backdrop-filter: blur(10px);
  z-index: 999999;
}

.drop-downs{
  display: flex;
  flex-direction: row;

}

.menu-container {
  padding-top: 60% !important;

}


/* Improved Responsive Header Controls */
.header-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-image {
  max-height: 50px;
  width: auto;
  transition: transform 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.05);
}

/* Language and Currency Selector Styles */
.language-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #EEAC1A;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: #111;
  transition: all 0.3s ease;
  z-index: 999999;
}

.language-selector:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Menu Button */
.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EEAC1A;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #111;
  transition: all 0.3s ease;
  z-index: 999999;
}

.menu-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Mobile Menu Styles */
.mobile-menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background: #222;
  z-index: 1000;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(15px);
  overflow-y: auto;
}

.mobile-menu.active {
  right: 0;
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 40% !important;
  padding: 2rem;
  position: relative;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;


}

.menu-items a {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #EEAC1A;
  text-decoration: none;
  padding: 1rem;
  border-radius: 8px;
  background: #111;
  transition: background-color 0.3s ease;
}

.menu-items a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.menu-items a svg {
  color: #EEAC1A;
  margin-right: 0.5rem;}
  

.close-menu-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #EEAC1A;
  cursor: pointer;
  background: #111;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.close-menu-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Dropdown Styles */
.language-dropdown,
.AppHeaderDropdown__menu {
  position: absolute;
  background: #2a2a2a;
  border: 1px solid #111;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.language-dropdown .dropdown-item,
.AppHeaderDropdown__menu .dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  color: #EEAC1A;
  padding: 0.5rem 1rem;
}

.language-dropdown .dropdown-item:hover,
.AppHeaderDropdown__menu .dropdown-item:hover {
  background: #111;
}

.currency-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currency-dropdown-item .flag-icon {
  margin-left: 0.5rem;
}

/* Adding overflow for scroll in dropdown */
.language-dropdown, 
.AppHeaderDropdown__menu {
  max-height: 250px;
  overflow-y: auto;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .pf-header-top {
    padding: 1rem;
    gap: 0.5rem;
  }

  .header-controls {
    width: 100%;
    justify-content: space-between;
  }

  .logo-section {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .logo-image {
    max-height: 40px;
  }

  .language-selector {
    padding: 0.3rem 0.75rem;
  }

  .menu-button {
    width: 35px;
    height: 35px;
  }

  .menu-items {
    max-width: 300px;
    gap: 0.75rem;
  }

  .menu-container {
    padding-top: 50% !important;

  }

  .menu-items a {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .header-controls {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .menu-container {
    padding-top: 120% !important;

  }

  .drop-downs{
    display: flex;
    flex-direction: row;
    left: 0rem;
    margin-right: auto;
  }

  .pf-header-top {
   display: block;
  }

  .mob-drop {
display: none;

  }

  .language-selector, 
  .menu-button {
    width: auto;
    padding: 0;
    right: 5%;
  }

  .close-menu-button {
    top: 0.5rem;
    right: 0.5rem;
    width: 40px;
    height: 40px;
  }

  .language-dropdown .dropdown-item span, 
  .AppHeaderDropdown__menu .dropdown-item span {
    display: none;
  }

  .language-dropdown .dropdown-item, 
  .AppHeaderDropdown__menu .dropdown-item {
    justify-content: center; /* Center the icons */
  }
}

/* Scrollbar Styling for Dropdowns */
.language-dropdown::-webkit-scrollbar,
.AppHeaderDropdown__menu::-webkit-scrollbar {
  width: 8px;
}

.language-dropdown::-webkit-scrollbar-track,
.AppHeaderDropdown__menu::-webkit-scrollbar-track {
  background: #111;
}

.language-dropdown::-webkit-scrollbar-thumb,
.AppHeaderDropdown__menu::-webkit-scrollbar-thumb {
  background: #EEAC1A;
  border-radius: 4px;
}

li.dropdown.nav-item {
  list-style-type: none !important;
  marker: none !important;
}
