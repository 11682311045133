.icon-bell {
  font-size: 20px;
  color: #fbc108;
}

.userName {
  color: white;
}

.app-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  z-index: 1020;
  color: #23282c;
  background: #282828;
  border-top: 1px solid #282828;
  flex: 0 0 60px;
  -ms-flex: 0 0 50px;
}

.banner {
  height: 750px;
}

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #E91E63;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-image: none;
}

.carousel-control-next-icon:after {
  content: '>';
  font-size: 30px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 30px;
  color: black;
}

.footer-part {
  background-color: #282828;
}

.text-color {
  color: white;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.row1 {
  padding: 1%;
}

.image {
  position: relative;
  width: 100%;
  /* for IE 6 */
}

.text-content {
  position: absolute;
  color: white;
  top: 75px;
  left: 0;
  width: 100%;
}

.thumbnail-text1 {
  position: absolute;
  font-size: 150%;
  color: white;
  top: 250px;
  /* left: 0;  */
  width: 100%;
}

/* .thumbnail-text2 { 
  position: absolute; 
  color: white;
  font-size: 350%;
  top: 300px; 
  width: 100%; 
 } */

.title-color {
  color: #fbc108;
}

.thumbnail-text3 {
  position: absolute;
  font-size: 1.3rem;
  color: white;
  top: 370px;
  /* left: 0;  */
  width: 100%;
}

.search-content {
  position: absolute;
  color: white;
  top: 80%;
  /* left: 30%; */
  height: 10%;
  width: 100%;
}

.thumbnail-text4 {
  position: absolute;
  color: white;
  top: 570px;
  /* left: 30%; */
  height: 10%;
  width: 100%;
}

.thumbnail-text4 .form-control {
  padding: 0.700rem 0.75rem;
}

.items {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 10px 10px 16px 10px;
  vertical-align: top;
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  /* height: 100%; */
  height: 230px;
  padding: 5%;
}

.top {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
  z-index: 0;
  /* height: 65%; */
  height: 75%;
}

.cat-sidebar {
  /* min-width: 285px; */
  /* max-width: 375px; */
  border-left: 1px solid #d9d4cf;
  border-bottom: 1px solid #d9d4cf;
}

.product-search {
  color: #23282c;
  background-color: #ffc107;
  border-color: #ffc107;
  padding: 0.375rem 0.75rem;
}

.catName:hover {
  font-size: 150%;
  color: #fbc108;
}

.cat-results {
  width: 100%;
  /* border-left: 1px solid #92887e; */
  margin-right: 4px;
}

.cat-wrapper {
  display: flex;
  background-color: white;
  width: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
}

.navB, .navB a, .navB ul, .navB li, .navB div, .navB form, .navB input {
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  border: none;
  outline: none;
}

.navB a {
  text-decoration: none;
}

.navB li {
  list-style: none;
}

.filter-header {
  padding: 10px 20px;
  border-top: 1px solid #d9d4cf;
  color: #8d8782;
  position: relative;
  /* padding-left: 35px; */
}

.navB {
  display: block;
  position: relative;
  cursor: default;
  z-index: 500;
}

.btn-link {
  cursor: pointer;
}

.navB>li {
  display: block;
  float: left;
}

.filter-header-title {
  color: #23282c;
  ;
  font-size: .875rem;
  margin: 0;
  line-height: 30px;
  padding-right: 30px;
  cursor: pointer;
  text-transform: uppercase;
}

.filter-header-count {
  color: #8d8782;
  border-radius: 50%;
  border: 1px solid #beb6ae;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 29px;
  text-align: center;
  float: right;
  top: 10px;
}

.navB>li>a {
  position: relative;
  display: block;
  z-index: 510;
  height: 54px;
  padding: 5px 30px;
  line-height: 54px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: black;
  text-shadow: 0 0 1px rgba(0, 0, 0, .35);
  background: white;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
}

.navB>li:hover>a {
  background: #fbc108;
}

.navB>li:first-child>a {
  border-radius: 3px 0 0 3px;
  border-left: none;
}

.cat-utility-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #d9d5cf;
  border-top: 1px solid #d9d5cf;
  /* max-height: -webkit-fill-available; */
}

.cat-utility-count {
  width: 100%;
  border-right: 1px solid #d9d4cf;
  line-height: 1.5em;
  padding: 7px 20px;
}

.cat-utility-sort {
  height: 33px;
  border: 0;
  /* width: -webkit-fill-available; */
  cursor: pointer;
  background-color: white;
}

.navB>li>div {
  position: absolute;
  display: block;
  width: 100%;
  top: 55px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  font-weight: bold;
  overflow: hidden;
  background: #fff;
  border-radius: 0 0 3px 3px;
  border: 2px solid lightgray;
  -webkit-transition: all .3s ease .15s;
  -moz-transition: all .3s ease .15s;
  -o-transition: all .3s ease .15s;
  -ms-transition: all .3s ease .15s;
  transition: all .3s ease .15s;
}

.navB>li:hover>div {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  cursor: pointer;
}

.navB .nav-column {
  float: left;
  width: 20%;
  padding: 1%;
  color: #fff;
  margin-top: -20px
}

.radio-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;
  font-size: 1rem;
  color: #23282d;
}

.radio-cursor {
  cursor: pointer;
}

.navB .nav-column h5 {
  margin: 13px 0 10px 0;
  line-height: 18px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #372f2b;
}

.navB .nav-column h3.orange {
  color: #ff722b;
}

.navB .nav-column li {
  display: block;
  line-height: 26px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #888888;
  margin-left: 22px;
}

.navB .nav-column li a:hover {
  color: #666666;
}

.cat-results-header {
  padding: 20px;
  border-bottom: 1px solid #d9d4cf;
  background-color: white;
}

.rotate {
  -moz-transition: all .5s linear;
  -webkit-transition: all .5s linear;
  transition: all .5s linear;
}

.product-thumb-label {
  font-size: .625rem;
}

.product-thumb-title {
  margin: 5px 0 10px;
  font-size: 16px;
  transform: scale(1);
  transition: color .25s ease-out;
  padding-right: 16px;
  font-weight: normal;
}

.product-thumb-price {
  font-size: 14px;
}

.product-thumb:hover {
  border-color: #fff;
  outline: #fbc108 solid 1px;
  cursor: pointer;
}

.cat-sidebar input.cat-search {
  display: flex;
  border: 0;
  font-style: italic;
  height: 52px;
  width: 100%;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org…2C12%2C5.51%2C5.51%2C0%2C0%2C1%2C1%2C6.5Z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
  padding-left: 46px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 20px 18px;
}

.cat-search-form {
  border-top: 1px solid #d9d4cf;
}

.text-family {
  font-family: "Open Sans,Arial,Helvetica,sans-serif";
}

.loader {
  font-size: 1.875rem;
  padding-left: 48%;
}

.a-cat {
  color: #372f2b !important;
  cursor: pointer;
}

.qty-button {
  border-radius: 50%;
  background-color: white;
  border-color: #a5aeb7;
}

.qty-btn {
  background-color: white;
}

.saveB {
  color: black;
}

@media only screen and (max-width: 320px) {
  #continueShopping-btn {
    margin-left: -30%;
  }
  #placeOrder-btn {
    margin-left: -9%;
  }
}

/* @media only screen and (max-width: 1024px){
  #continueShopping-btn{
    margin-left: 0%;
}
#placeOrder-btn{
  margin-left: -20%;
}
} */

.cart-main-area {
  width: 100%;
  background-color: #ffffff;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 100px;
  margin-bottom: 100px;
}

.cart-header-area {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}

.cart-header-text {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  height: 35px;
}

.cart-sub-header-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b4b4b4;
}

.cart-header-clear-cart {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #707070;
  cursor: pointer;
  padding-right: 8%;
}

.cart-header-clear-cart:hover {
  color: red !important;
}

.cart-entry-header-seperator {
  background-color: #707070;
  width: 100%;
  height: 1px;
  margin-bottom: 40px;
}

.cart-main-area-header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  color: #000000;
}

.cart-entry-footer-seperator {
  background-color: #707070;
  width: 100%;
  height: 1px;
  margin-bottom: 40px;
  margin-top: 100px;
}

.cart-total-info {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 900;
  color: #000000;
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
  padding-left: 5%;
  padding-right: 10%;
}

.cart-button {
  width: 200px;
  height: 50px;
  background-color: #000000;
  margin-right: 10px;
  color: #ffffff;
}

.cart-empty-container {
  width: '100%';
  height: 525px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cart-empty-message {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 8px;
}

.cart-empty-shop-more-message {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #b4b4b4;
  margin-bottom: 60px;
}