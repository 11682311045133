
.icon-bell{
	font-size: 20px;
	color:#fbc108;
}

.userName{
	color:white;
}

.app-footer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 1rem;
	z-index: 1020;
	color: #23282c;
	background: #282828;
	border-top: 1px solid #282828;
	flex: 0 0 60px;
	-ms-flex: 0 0 50px;
}

.banner {
	height: 750px;
}

.curson-default {cursor: default;}
.carousel-indicators li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #E91E63;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 30px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 30px;
  color: black;
}

.footer-part{
	background-color: #282828;
}

.text-color{
	color: white;
}

.row {
    
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px; 
    margin-left: 0px;
}

.rowB{
    
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px; 
    margin-left: 10px;
}

.row1{
  padding: 1%;
}

.image { 
  position: relative; 
  width: 100%; /* for IE 6 */
}

.text-content { 
 position: absolute; 
 color: white;
 top: 75px; 
 left: 0; 
 width: 100%; 
}


 
.title-color{
  color:#fbc108;


}
 
.search-content{
  position: absolute; 
  color: white;
  top: 80%;
  /* left: 30%; */
  height: 10%;
  width: 100%; 

}

.thumbnail-text4 .form-control{
  padding: 0.700rem 0.75rem;
}


.items {
  background-color: rgba(255,255,255,0.9);
  margin: 10px 10px 16px 10px;
  vertical-align: top;
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  height: 230px;
  padding: 5%;
  cursor: pointer;
}

.top {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
  z-index: 0;
  height: 100%;
}


.cat-sidebar {
  /* min-width: 285px; */
  /* max-width: 375px; */
  border-left: 1px solid #d9d4cf;
  border-bottom: 1px solid #d9d4cf;
}

.product-search{
  color: #23282c;
    background-color: #ffc107;
    border-color: #ffc107;
    padding: 0.375rem 0.75rem;
}

.catName:hover{
  font-size: 150%;
  color: #fbc108;
  }
.cat-results {
  width: 100%;
  /* border-left: 1px solid #92887e; */
  margin-right: 4px;
}

.cat-wrapper {

  display: flex;
  background-color: white;
  width: -webkit-fill-available;
}

body{
	margin: 0;
	padding: 0;
}
.navB,
.navB a,
.navB ul,
.navB li,
.navB div,
.navB form,
.navB input {
    margin: auto;
    margin-left: auto;
    margin-right: auto;
    padding:0;
    border: none;
    outline: none;
}
 
.navB a { text-decoration: none; }
 
.navB li {
     list-style: none; 
    }
.filter-header {
   padding: 10px 20px;
  border-top: 1px solid #d9d4cf;
  color: #8d8782;
  position: relative;
  /* padding-left: 35px; */
}

.navB {
    display: block;
    position: relative;
    cursor: default;
    z-index: 500;
}
 .btn-link {
  cursor: pointer;
}

.navB > li {
    display: block;
    float: left;
}
.filter-header-title {
  color: #23282c;;
  font-size: .875rem;
  margin: 0;
  line-height: 30px;
  padding-right: 30px;
  cursor: pointer;
  text-transform: uppercase;
}

.filter-header-count {
  color: #8d8782;
  border-radius: 50%;
  border: 1px solid #beb6ae;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 29px;
  text-align: center;
  float: right;
  top: 10px;
}


.navB > li > a {
    position: relative;
    display: block;
    z-index: 510;
    height: 54px;
    padding: 5px 30px;
    line-height: 54px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: black;
    text-shadow: 0 0 1px rgba(0,0,0,.35);
    background: white;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
}
.navB > li:hover > a { background:#fbc108; }
 
.navB > li:first-child > a {
    border-radius: 3px 0 0 3px;
    border-left: none;
}

.cat-utility-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #d9d5cf;
  border-top: 1px solid #d9d5cf;
  /* max-height: -webkit-fill-available; */
}

.cat-utility-count {
  width: 100%;
  border-right: 1px solid #d9d4cf;
  line-height: 1.5em;
  padding: 7px 20px;
}

.cat-utility-sort {
  height: 33px;
  border: 0;
  /* width: -webkit-fill-available; */
  cursor: pointer;
  background-color: white;
}

.navB > li > div {
    position: absolute;
    display: block;
    width: 100%;
    top: 55px;
    left: 0; 
    opacity: 0;
    visibility: hidden;
    font-weight: bold;
    overflow: hidden;
    background: #fff;
    border-radius: 0 0 3px 3px;
    border: 2px solid lightgray;
 
    -webkit-transition: all .3s ease .15s;
    -moz-transition: all .3s ease .15s;
    -o-transition: all .3s ease .15s;
    -ms-transition: all .3s ease .15s;
    transition: all .3s ease .15s;
}
.navB > li:hover > div {
    opacity: 1;
    visibility: visible;
    overflow: visible;
    cursor: pointer;
}

.navB .nav-column {
    float: left;
    width: 20%;
    padding: 1%;
    color: #fff;
    margin-top: -20px
}

.radio-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;
  font-size: 1rem;
  color : #23282d;
}

.radio-cursor{
  cursor: pointer;
}
 
.navB .nav-column h5 {
    margin: 13px 0 10px 0;
    line-height: 18px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #372f2b;
    
}
 
.navB .nav-column h3.orange { color: #ff722b; }
 
.navB .nav-column li  {
    display: block;
    line-height: 26px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: #888888;
    margin-left: 22px;
}
 
.navB .nav-column li a:hover { color: #666666; }
.cat-results-header {
  padding: 20px;
  border-bottom: 1px solid #d9d4cf;
  background-color: white;
}

.rotate {
    -moz-transition: all .5s linear;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
}

.product-thumb-labelSD {
  font-size: .825rem;
  margin-right: 45px
}

.product-thumb-titleSD {
  float: none;
  font-family: Arial;
  margin: 0 0;
  padding: 10px;
  width: 219px;
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  margin-top: -7px;
}

.product-thumb-priceSD {
  font-size: 14px;
  margin-right: 36px;
  margin-top: -12px;
}

.product-thumb:hover {
  border-color: #fff;
  outline: #fbc108 solid 1px;
  cursor: pointer;
}

.cat-sidebar input.cat-search {
  display: flex;
  border: 0;
  font-style: italic;
  height: 52px;
  width: 100%;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org…2C12%2C5.51%2C5.51%2C0%2C0%2C1%2C1%2C6.5Z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
  padding-left: 46px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 20px 18px;
}

.cat-search-form {
  border-top: 1px solid #d9d4cf;
}

.product-thumb-divSD{
    height: 100%;
    /* max-width: 200px; */
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 2% 10px;
    border-bottom: 1px solid rgb(217, 212, 207);
    border-right: 1px solid rgb(217, 212, 207);
    border-top: 1px solid rgb(217, 212, 207);
    border-left: 1px solid rgb(217, 212, 207);
}

.text-family{
  font-family : "Open Sans,Arial,Helvetica,sans-serif";
}

.loader{
  font-size: 1.875rem;
  padding-left: 48%;
}

.resul-not-found {
  margin-left: 37%;
}

.a-cat{
  color:#372f2b !important;
  cursor: pointer;
}
.kurentovideo {
  width: 100%;
}


/* chat conversation css */

.header
{
  font-size:26px;
  margin:0 auto;
  text-align:center;
  color:#9F6905;
  font-weight:lighter;
  background-color:#f0f0f0;
  height:50px;
  line-height:50px;
  border-bottom:1px solid #9F6905;
}
.chat-container
{
  width:auto;
  cursor:default;
  margin:-20px auto;
  max-height:350px;
  overflow-y:scroll;
}
.chat-container::-webkit-scrollbar 
{
width: 3px;
max-width: 3px;
height: auto;
max-height: 8px;
}
.chat-container::-webkit-scrollbar-thumb 
{
background: #f0f0f0;
border-radius: 5px;
max-width: 3px;
}
.chat-container::-webkit-scrollbar-track {
background: #f0f0f0;
  border-radius:5px;
}
.Area
{
 
  background-color:rgba(240, 240, 240, 0.2);
  display:table;
  padding:5px;
  border-radius:5px;
  margin-bottom:10px;
}
.L
{
  float:left;
}
.chat-img
{
  width:50px;
  height:50px;
  border-radius:50%;
  border:2px solid #f0f0ff;
  padding:5px;    
}
.chat-img:hover
{
    -moz-box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
-webkit-box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
       -webkit-transition: all 1.5s;
    -moz-transition: all 1.5s;
    transition: all 1.5s;
  cursor:pointer;
}
.R
{
    float:right;
}
.text
{
  color: #a4a4a4;
  font-family: tahoma;
  font-size: 13px;
  font-weight:bold;
  line-height: 30px;
  width:300px;
  background-color:rgba(255, 255, 255, 0.6);
  margin-top:10px;
  border-radius:5px;
  padding:5px;  
}
.Area textarea
{
  font-size:12px;
  width:90%;
  max-width:90%;
  min-width:90%;
  padding:5%;
  border-radius:5px;
  border:1px solid #f0f0f1;
  max-height:50px;
  height:50px;
  min-height:50px;
  background-color:#333;
  color:#fff;
  outline:none;
  border:1px solid transparent;
  resize:none;
}
.Area textarea:focus
{
  color:#333;
  border:1px solid #ccc;
     -webkit-transition: all 1.5s;
    -moz-transition: all 1.5s;
    transition: all 1.5s;
  background-color:#fff;
}
.Area .note
{
  color:#9F6905;
  font-size:10px;
}
.R .tooltip
{
  font-size:10px;
  position:absolute;
  background-color:#fff;
  padding:5px;
  border-radius:5px;
  border:2px solid #9F6905;
  margin-left:70px;
  margin-top:-70px;
  display:none;
  color:#545454;
}
.R .tooltip:before
{
    content: '';
    position: absolute;
    width: 1px;
    height: 1px;
    border: 5px solid transparent;
    border-right-color: #9F6905;
    margin-top: 10px;
    margin-left: -17px;
}
.R:hover .tooltip
{
  display:block;
}

.L .tooltip
{
  font-size:10px;
  position:absolute;
  background-color:#fff;
  padding:5px;
  border-radius:5px;
  border:2px solid #9F6905;
  margin-left:70px;
  margin-top:-70px;
  display:none;  
  color:#545454;
}
.L .tooltip:before
{
    content: '';
    position: absolute;
    width: 1px;
    height: 1px;
    border: 5px solid transparent;
    border-right-color: #9F6905;
    margin-top: 10px;
    margin-left: -17px;
}
.L:hover .tooltip
{
  display:block;
}
a
{
  text-decoration:none;
}


.Area input[type=button]
{
  font-size:12px;
  padding:5px;
  border-radius:5px;
  border:1px solid #f0f0f1;
  background-color:#333;
  color:#fff;
  outline:none;
  border:1px solid transparent;
  float:left;
}
.Area input[type=button]:hover
{
  color:#fff;
  border:1px solid #ccc;
     -webkit-transition: all 1.5s;
    -moz-transition: all 1.5s;
    transition: all 1.5s;
  background-color:#9F6905;
} 
.validation
{
  float:left;
  background-color:#ccc;
  border-radius:5px;
  padding:5px;
  font-size:12px;
  line-height:14px;
  height:0px;
  margin-left:5px;
  display:none;
}
br
{
  clear:both;
  height:20px;
}
.chat-placeholder {
    width: 100%;
}

.sender-name {
  font-weight: bold;
  color: #6c6a6a;
}

.mar-top-button {
  margin-top: 18%;
}
.mar-top-1 {
  margin-top: 1%;
}

.streaming-card-height {
  height: 25em;
}
.media-box > video {
  width: 100%;
  height: 23em;
}