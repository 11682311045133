.details-carousel .slick-dots {
  position: absolute;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  bottom: -35px;
  left: 0;
}

.details-carousel .ft-slick__dots--custom {
    height: 8px;
    width: 8px;
    background-color: #e5e7e9;
    border-radius: 50%;
    position: relative;
  }

  .details-carousel .slick-dots li {
    width: 14px;
    margin-right: 12px;
    margin-left: 0;
    /* transition: width 0.3s ease-in-out; */
  }

  .details-carousel .slick-dots .slick-active {
    width: 105px;
    /* transition: width 0.3s ease-in-out; */
  }

  .details-carousel .slick-dots .slick-active .ft-slick__dots--custom {
    width: 17px;
    height: 17px;
    background: unset;
    background-image: url('../../../assets/img/active-dot.png');
    background-size: 17px 17px;
    top: -4px;
    background-repeat: no-repeat;
  }

  .details-carousel .slick-dots .slick-active .ft-slick__dots--custom .loading {
    width: 75px !important;
    height: 1px;
    background-color: white;
    left: 25px;
    position: absolute;
    top: 50%;
  }




.details-carousel {
    position: absolute;
    bottom: 10%;
    left: 0%;
    right: 0;
    margin: 0 auto;
  display: none;
    width: 335px;
  }

  .details-carousel .slick-slider {
    width: 475px !important;
  }

  .details-carousel .items-container {
    height: 165px;
  }
  
  .details-carousel .product-img {
    position: absolute;
    left: 2px;
    width: 90px;
    height: unset !important;
    border-radius: 6px;
    top: 85px;
    transform: translateY(-50%);
    box-shadow: 0px 0px 10px 5px rgb(45 52 61 / 30%);
  }
  
  .details-carousel .upper-carousel-inner {
     margin-left: 110px;
    width: 100%;
    padding: 10px 10px 10px 0;
    
  }
  
  .details-carousel .banner-column {
    padding: 5px 5px 5px 0px;
  }
  
   .details-carousel .attribute-text{
    height: unset;
  }
  
  .details-carousel .buy-now {
    height: 25px;
    width: 84px;
    font-size: 12px;
    cursor: context-menu;
  }
  
  .details-carousel .rating-value {
    font-size: 12px;
    margin-left: 5px;
  }
  
  .details-carousel .add-to-cart {
    height: 25px;
    width: 25px;
    background-size: 17px;
    cursor: context-menu;
  }
  
  .details-carousel .suggestion-reject , .details-carousel .suggestion-accept {
      width: 25px;
      height: 25px;
      background-size: 15px;
      background-position: center;
  }
  
  .details-carousel .price-input {
    height: 25px;
    pointer-events: none;
    border-color: #cfcfcf !important;
    font-size: 12px;
  }
  
  .details-carousel .carousel {
    width: 390px;
    box-shadow: 3px -3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  height:100%;
  
  }
  
  .details-carousel .value-text {
    font-size: 12px;
  }
    
  .carousal-slider .slick-next, .carousal-slider .slick-prev {
    display: none !important;
  }  
    
    .carousal-controles-container{
      display: flex;
      flex-direction: column;
      height: 500px;
    }
    
    /* .carousal-button-left {
      width: 82px;
      height: 82px;
      object-fit: contain;
      background-image: url('../../../assets/img/carousal-left-arrow.svg');
      border: none;
      margin-right: 2px;
    }
    
    .carousal-button-right {
      width: 82px;
      height: 82px;
      object-fit: contain;
      background-image: url('../../../assets/img/carousal-right-arrow.svg');
      border: none;
  
    } */
    
    .carousal-transition {
      position:absolute;
      left:0;
      -webkit-transition: opacity 2s ease-in-out;
      -moz-transition: opacity 2s ease-in-out;
      -o-transition: opacity 2s ease-in-out;
      transition: opacity 2s ease-in-out;
    }
    
    .carousel-image-container {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    
    .carousel-check-now-button {
      width: 180px;
      height: 50px;
      background-color: #000000;
      color: #ffffff;
      margin-top: 50px;
    }
    
    .carousal-dot-custom{
      width: 10px;
      height: 12px;
      margin-top: 4px;
      object-fit: contain;
      background-color: #fff;
      border-radius: 50%;
      border: none;
      background-image: url('../../../assets/img/carousal-dot.svg');
    }
  
    .carousel-brand-text{
      font-family: Roboto;
      font-size: 45px;
      font-weight: 100;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -2.65px;
      text-align: left;
      color: #000000;
    }
  
    .carousel-product-name-text{
      font-family: Roboto;
      font-size: 45px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -2.65px;
      text-align: left;
      color: #000000;
    }
  
    .carousal-position-line {
      width: 80px;
      height: 2px;
      background-color: #fff;
      margin-left: 12.5px;
    }

    .details-carousel .name-text {
      font-size: 14px;
    }

    .details-carousel .button-text {
      font-size: 13px !important;
    }

    @media screen and (min-width: 1499px) {
      .details-carousel .carousel {
        width: 415px;
        height: 185px;
      }

      .details-carousel .upper-carousel-inner {
        padding: 10px 10px 10px 0;
      }

      .details-carousel .name-text, .details-carousel .button-text {
        font-size: 16px !important;
      }

      .details-carousel .value-text {
        font-size: 14px;
      }

      .details-carousel .price-input {
        height: 32px;
      }

      .details-carousel .suggestion-reject, .details-carousel .suggestion-accept {
        width: 32px;
        height: 32px;
        background-size: 18px;
      }



      .details-carousel .buy-now {
        width: 92px;
        height: 32px;
        margin-left: 10px;
        pointer-events: none;
      }

      .details-carousel .slick-dots li {
        margin-right: 14px;
      }

      .details-carousel .slick-dots .slick-active .ft-slick__dots--custom {
        top: -2px;
      }

      .details-carousel .ft-slick__dots--custom {
        height: 12px;
        width: 12px;
      }

      .details-carousel .add-to-cart {
        width: 32px;
        height: 32px;
        background-size: 22px;
        pointer-events: none;
        cursor: context-menu;
      }

      .details-carousel .slick-slider {
        width: 510px !important;
        height: 180px;
      }

      .details-carousel .slick-dots {
        bottom: -50px;
      }

      .details-carousel .slick-list {
        height: 250px !important;
      }

      .details-carousel .product-img {
        top: 92px;
      }

      .details-carousel {
        width: 350px;
      }
    }
  
    .selected-dot{
      width: 24px;
      height: 20.1px;
      object-fit: contain;
      background-image: url('../../../assets/img/carousal-dot-selected.svg');
  
      padding: 0;
      margin-bottom: 0;
      border: none;
      outline: none;
      font: inherit;
      color: inherit;
      background-color: #000;
    }
  
  
    .carousel-product-desc {
      width: 100%;
      height: 48px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #b4b4b4;
      padding-right: 20%;
  
  
      margin-top: 27px;
    }
  
    .button-text {
      font-size: 12px !important;
    }
  
    @media screen and (min-width: 992px) {
      .details-carousel {
        display: block;
      }
    }