/* Base styles and variables */
:root {
  --primary-color: #EEAC1A;
  --primary-dark: #EEAC1A;
  --secondary-color: #f8fafc;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --card-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --transition-base: all 0.3s ease;
}

/* Page wrapper */
.cae-page-wrapper {
  background: linear-gradient(135deg, #f8fafc 0%, #e2e8f0 100%);
  min-height: 100vh;
  padding: 2rem 0;
}

/* Header section */
.cae-header {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #f3f4f6;
}

.cae-heading {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-weight: 700;
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--primary-dark) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cae-subheading {
  font-size: 1.2rem;
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto;
}

/* Container */
.cae-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;

}

/* Event cards grid */
.cae-event-details-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

/* Event card */
.cae-event-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  box-shadow: var(--card-shadow);
  transition: var(--transition-base);
  cursor: pointer;
}

.cae-event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Event card badge */
.cae-event-card-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #EEAC1A;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 2;
}

/* Thumbnail */
.cae-event-card-thumbnail {
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.cae-event-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
}

/* Card content */
.cae-event-card-content {
  padding: 1.5rem;
}

.cae-event-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 1rem;
  line-height: 1.4;
}

/* Event details */
.cae-event-card-details {
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.cae-event-detail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.cae-event-price {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 1.25rem;
}

/* Button */
.cae-learn-more-btn {
  width: 100%;
  padding: 0.75rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition-base);
}

.cae-learn-more-btn:hover {
  background: var(--primary-dark);
}

/* Loading state */
.cae-loading {
  text-align: center;
  padding: 4rem;
}

.cae-loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* No events state */
.cae-no-events {
  text-align: center;
  padding: 4rem;
  color: var(--text-secondary);
}

.cae-check-back {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

/* Icons */
.cae-icon-calendar::before {
  content: "📅";
}

.cae-icon-time::before {
  content: "⏰";
}

.cae-icon-calendar-x::before {
  content: "📅❌";
  font-size: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cae-heading {
    font-size: 2rem;
    margin-top: 17%;
  }
  
  .cae-subheading {
    font-size: 1rem;
  }
  
  .cae-container {
    padding: 0 1rem;
  }
}

@media (min-width: 426px) {
  .cae-heading {
    font-size: 2rem;
    margin-top: 6%;
  }
  
  .cae-subheading {
    font-size: 1rem;
  }
  
  .cae-container {
    padding: 0 1rem;
  }
}