.close {
    position: absolute;
    right: -40px;
    top: 4px;
    width: 12px;
    height: 11px;
    opacity: 1 !important;
    background-color: white;
  }
  
  .close:before, .close:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  