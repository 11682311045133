/* Base styles and variables */
:root {
  --primary-color: #EEAC1A;
  --primary-dark: #2c2c2c;
  --secondary-bg: #1a1a1a;
  --text-primary: #ffffff;
  --text-secondary: #c0c0c0;
  --card-shadow: 0 15px 35px -10px rgba(238, 172, 26, 0.2);
  --transition-base: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Page Layout */
.my-courses-page {
  min-height: 100vh;
  background: #ffffff;
  color: var(--text-primary);
}

.page-content {
  width: 100%;
  padding: 6rem 2rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Page Header */
.page-header {
  margin-bottom: 2rem;
  padding: 2rem;
  background: rgba(44, 44, 44, 0.8);
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(238, 172, 26, 0.1);
}

.page-header h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  word-wrap: break-word;
}

.header-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(238, 172, 26, 0.2);
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  flex: 1;
  min-width: 120px;
}

.stat-item:hover {
  background-color: rgba(238, 172, 26, 0.1);
}

.stat-value {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--primary-color);
  text-align: center;
}

.stat-label {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-top: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
}






.sort-select {
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(238, 172, 26, 0.2);
  border-radius: 8px;
  color: var(--text-primary);
  min-width: 150px;
  flex-shrink: 0;
}






/* Media Card Grid */
.media-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
  width: 100%;
}

.media-card {
  background: rgba(44, 44, 44, 0.8);
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: var(--card-shadow);
  transition: var(--transition-base);
  cursor: pointer;
  border: 1px solid rgba(238, 172, 26, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
}

.media-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 25px 50px -15px rgba(238, 172, 26, 0.3);
}

.media-card-thumbnail {
  height: 220px;
  background-size: cover;
  background-position: center;
  position: relative;
  filter: brightness(0.9);
  transition: filter 0.3s ease;
  flex-shrink: 0;
}

.media-card:hover .media-card-thumbnail {
  filter: brightness(1);
}

.media-card-content {
  padding: 1.5rem;
  color: var(--text-primary);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.media-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  line-height: 1.4;
  word-break: break-word;
}

.media-card-info {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-top: auto;
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 4rem 2rem;
  background: rgba(44, 44, 44, 0.8);
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(238, 172, 26, 0.1);
}

.empty-state h2 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  word-wrap: break-word;
}

.empty-state p {
  color: var(--text-secondary);
  margin-bottom: 2rem;
}

.browse-courses-btn {
  padding: 0.75rem 2rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-base);
  box-shadow: 0 8px 25px rgba(238, 172, 26, 0.4);
  display: inline-block;
}

.browse-courses-btn:hover {
  transform: translateY(-4px);
  background: var(--text-primary);
  color: var(--primary-color);
  box-shadow: 0 12px 30px rgba(238, 172, 26, 0.5);
}

/* Loading State */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Error State */
.error-message {
  text-align: center;
  padding: 2rem;
  background: rgba(220, 38, 38, 0.1);
  color: #dc2626;
  border-radius: 12px;
  margin: 2rem 0;
  border: 1px solid rgba(220, 38, 38, 0.2);
}

/* Responsive Design Improvements */
@media (max-width: 1024px) {
  .page-content {
    padding: 5rem 1.5rem 1.5rem;
  }

  .header-stats {
    justify-content: center;
  }

  .stat-item {
    flex-basis: calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  .page-content {
    padding: 4rem 1rem 1rem;
  }

  .page-header h1 {
    font-size: 2rem;
    text-align: center;
  }

  .content-filters {
    flex-direction: column;
    align-items: stretch;
  }



  
  .sort-select {
    width: 100%;
    min-width: 100%;
  }

  .header-stats {
    flex-direction: column;
    align-items: stretch;
  }

  .stat-item {
    flex-basis: 100%;
  }
}

@media (max-width: 480px) {
  .page-header h1 {
    font-size: 1.75rem;
  }

  .stat-value {
    font-size: 1.5rem;
  }

  .media-card-title {
    font-size: 1.1rem;
  }

  .media-card-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .media-card-thumbnail {
    height: 180px;
  }
}