.button-style {
  background: transparent;
  color: #f05025;
  cursor: pointer;
  border: 0.5px solid grey;
}
.button-style :hover {
  color: #428bca;
}

.address-border {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
}

.address-border:last-child {
  border-left: none;
}
.adress-edit-text {
  font-size: 20px;
}
