.checkout-top-bar {
  width: 100%;
  height: 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-top: 11px;
  display: flex;
  justify-content: center;
  margin-top: 74px;
}

.checkout-top-bar-inner {
  width: 80%;
  min-width: 750;
  display: flex;
  justify-content: center;
}

.checkout-top-bar-inner-section {
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 257px;
}

.checkout-top-bar-inner-section-last {
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 157px;
}

.checkout-top-bar-inner-section-img {
  margin-right: 10px;
  width: 45px;
  height: 45px;
}

.checkout-top-bar-inner-section-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #b4b4b4;
}

.checkout-top-bar-inner-section-text-completed {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #00ff6f;
}

@media only screen and (max-width: 891px) {
  .hide-text-on-mobile {
      display: none;
  }
}

.checkout-content{
  min-height:60vh;
  padding-top: 60px;
}