table img {
  max-width: 100px;
}
.custom-footer {
  bottom: 0px;
  /* position: fixed; */
}
.cms-container-fluid {
  margin-top: 100px;
  padding: 20px;
}
