.TestimonialContact {
  width: 100vw;
  display: flex;
  height: 200px;
  gap: 0.3rem;

  text-align: center;
    padding: 3vw 5vw;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}


.TestimonialContact-main{
  background-color: white;
  padding-top: 4vh;
  padding-bottom:4vh;
  margin-top: 2rem;
  

}

.TestimonialContact-heading
{
  text-align: center;
font-weight: bold;
padding: 1.5rem 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #f3f4f6;


}


.Contact1,
.Contact2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.Contact1 {
  background-color: #EEAC1A;
}

.Contact1 h2 {
  color: black;
}

.Contact2 {
  background-color: black;
}

.Contact2 h2 {
  color: #EEAC1A;
}

/* Individual hover effects */
.Contact1:hover {
  background-color: black;
}

.Contact1:hover h2 {
  color: #EEAC1A;
}

.Contact2:hover {
  background-color: #EEAC1A;
}

.Contact2:hover h2 {
  color: black;
}