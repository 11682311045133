.featured-carousel {
    max-width: 260px !important;
    height: 260px;
    background-color: #fff;
    margin: auto;
}

.featured-carousel .featured-img {
   
    height: 190px;
    max-width: 190px;
    margin: auto;
}

.featured-carousel .featured-name-text {
    font-size: 13px;
    font-weight: 400;
}

.featured-carousel .featured-brand-text {
    font-size: 11px;
    font-weight: 400;
    color: #9b9b9b;
    text-align: left !important;
}

.slick-slider {
    position: unset;
    margin: auto;
    width: 90%;
    max-width: 1500px;
}
.slick-slider.features {
    position: unset;
    margin: auto;
    width: 90%;
    max-width: 900px !important;
}

.slick-container {
    text-align: center;
    margin: auto;
    background-color: #eeeeef;
    padding: 2rem 0;
}

.slick-prev, .slick-next {
    height: 15px;
    width: 15px;
    background-size: contain;
    background-repeat: no-repeat;

    /* to position the arrows left and right at the bottom of slider */
    position: absolute;
    z-index: 10;
    bottom: 0;
}

.slick-prev {
    background-image: url('../../../assets/img/carousal-left-arrow.svg') !important;
    height: 65px;
    width: 65px;
    object-fit: contain;
    border: none;
    left: 0;
}
.slick-next {
    width: 65px;
    height: 65px;
    object-fit: contain;
    background-image: url('../../../assets/img/carousal-right-arrow.svg') !important;
    border: none;
    right: 0;
}

.header-font {
    font-size: 30px;
  }

.slick-prev:before, .slick-next:before {
    display: none;
}

.black-bg-btn {
    background-color: #000000;
    color: #fff;
    font-weight: normal;
    border-radius: unset;
    box-shadow: none;
  }
  
  .black-bg-btn:hover {
    color: #fff;
  }

  .button-text {
    font-size: 12px;
  }

@media screen and (max-width: 768px) {
    .slick-prev, .slick-next {
      width: 50px;
      height: 50px;
    }
  }

@media screen and (min-width: 480px) {
    .header-font {
      font-size: calc(30px + 6 * ((100vw - 480px) / 900));
    }
  }

  @media screen and (min-width: 1700px) {
    .header-font {
      font-size: 43px;
    }

    .button-text {
        font-size: 14px;
      }
}