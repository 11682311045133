.onerror {
  color: red;
}

.image-padding {
  padding-right: 2%;
  cursor: pointer;
}

.imag:hover .middle {
  opacity: 1;
}

.container {
  position: relative;
  width: 100%;
  display: inline-block;
}

.image {
  width: 100%;
  height: auto;
  display: inline-block;
  flex-wrap: wrap;
  padding: 3px 4px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  cursor: pointer;
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.category-padding {
  padding-left: 5%;
  padding-right: 10%;
}

.close {
  position: absolute;
  right: 6px !important;
  top: 0px;
  width: 14px;
  height: 14px;
  opacity: 1 !important;
  background-color: red;
}

.close:before,
.close:after {
  position: absolute;
  left: 5px;
  content: " ";
  height: 10px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

/* MEDIA QUERY for screens less than 375px */
@media only screen and (max-width: 375px) {
  .category-dropdowns {
    width: 100%;
  }
}
