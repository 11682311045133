.pimage:hover {
  cursor: pointer;
  transform: scale(1.3, 1.3);
  opacity: 1;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
}

.imageB:hover {
  cursor: pointer;
  border: 2px solid #fbc108;
}
.price-detail-wrapper {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(7, 7, 7, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  opacity: 10px;
}
.close_icon {
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-top: 20px;
  font-size: 24px;
  cursor: pointer;
}

.price-detail-heading {
  padding-left: 20px;
  font-weight: 600;
  font-size: 14px;
}
.priceModal {
  height: 50%;
  width: 40%;
  background-color: white;
  border-radius: 10px;
  overflow-y: auto;
}
.product-detail-heading {
  font-size: 24px;
  font-weight: 600;
  padding-left: 20px;
}
.error-tag-line {
  padding-left: 20px;
}
.newPrice_text {
  font-weight: 600;
}
.material-cost-text {
  font-weight: 600;
}
.ellipsis {
  overflow: hidden;
  /* height: 200px; */
  line-height: 25px;
  margin: 20px;
  /* border: 5px solid #AAA; */
}

.ellipsis:before {
  content: "";
  float: left;
  width: 5px;
  height: 200px;
}

.ellipsis > *:first-child {
  float: right;
  width: 100%;
  margin-left: -5px;
}

.ellipsis:after {
  content: "\02026";
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  float: right;
  position: relative;
  top: -25px;
  left: 100%;
  width: 3em;
  margin-left: -3em;
  padding-right: 5px;
  text-align: right;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(white),
    color-stop(50%, white)
  );
  background: -moz-linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 50%,
    white
  );
  background: -o-linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 50%,
    white
  );
  background: -ms-linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 50%,
    white
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 50%,
    white
  );
}

.qty-button {
  border-radius: 50%;
  background-color: white;
  border-color: #a5aeb7;
}

.qty-btn {
  background-color: white;
}

.detail-price {
  color: red;
}

.offers {
  color: darkolivegreen;
}

.offers-manage {
  margin-top: -17px;
}

.product-price {
  margin-left: 15px;
}

@media only screen and (max-width: 375px) {
  #decrement-btn {
    margin-left: 20%;
  }
}
@media only screen and (min-width: 320px) {
  .product-content {
    padding: 30px;
  }
}

@media only screen and (max-width: 320px) {
  #increment-btn {
    margin-right: -2%;
  }
}

.product-title {
  font-family: Roboto;
  font-size: 34px;
  font-weight: 900;
  font-stretch: normal;
  line-height: 1;
  font-style: normal;
  text-align: left;
  color: #000000;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.digital-product-title {
  font-size: 25px;
  font-weight: 900;
  line-height: 1;
  text-align: left;
  color: #000000;
  padding-top: 5px;
}

.digital-product-details {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.related-item-holder {
  display: flex;
  margin-top: 50px;
}

.digital-product-title-holder {
  width: 75%;
}

.digital-product-download-button {
  width: 100%;
  margin: 5px;
}

.digital-product-price-holder {
  width: 20%;
  margin: 5px;
  border: 1px solid;
  border-radius: 5px;
  padding: 18px;
}
.digital-product-price {
  padding: 5px;
  margin-bottom: 0px;
}
.digital-product-stock {
  padding: 5px;
  margin-bottom: 0px;
}
.product-brand {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1px;
  text-align: left;
  color: #000000;
  margin-top: 3px;
}

.product-description {
  max-height: 88px;
  min-height: 60px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b4b4b4;
  overflow: hidden;
  margin-top: 15px;
}
.digital-product-note {
  font-size: 10px;
}
.availability-tag {
  width: 110px;
  height: 28px;
  background-color: #00ff6f;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 5px;
}

.unavailability-tag {
  width: 140px;
  height: 28px;
  background-color: #ff004e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 5px;
}

.attribute-text {
  height: 25px;
  min-width: 100px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  text-transform: capitalize;
}

.attribute-text-qantity {
  height: 25px;
  min-width: 100px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  text-transform: capitalize;
}
.attribute-select {
  width: 260px;
  height: 27px;
  border-radius: 2px;
  border: solid 0.3px #707070;
  background-color: #ffffff;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.quantity-minus {
  border: none;
  width: 30px;
  height: 25px;
  background-color: #000;
  color: white;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
}

.quanttity-count {
  font-size: 22px;
  margin: 0px 8px;
}

.pricing-section {
  width: 100%;
  max-width: 680px;
  min-height: 190px;
  background-color: #eeeeef;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.rating-value {
  width: 46px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b4b4b4;
  margin-left: 10px;
}

.price-input {
  width: 150px;
  height: 50px;
  border: solid 2px #ff004e;
  background-color: #ffffff;
  padding: 5px;
  margin-right: 4px;
}

.price-input-accepted {
  width: 150px;
  height: 50px;
  border: solid 0 #ff004e;
  background-color: #ffffff;
  padding: 5px;
  margin-right: 4px;
  transition: border 0.2s linear;
}

.suggestion-reject {
  width: 50px;
  height: 50px;
  object-fit: contain;
  background-image: url("../../../assets/img/product-detail/suggestion-reject.svg");
}

.suggestion-accept {
  width: 50px;
  height: 50px;
  object-fit: contain;
  background-color: #00ff6f;
  background-image: url("../../../assets/img/product-detail/suggestion-accept.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.buy-now {
  width: 150px;
  height: 50px;
  background-color: #000000;
  border: none;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-right: 4px;
}

.add-to-cart {
  width: 50px;
  height: 50px;
  background-color: #d5d5d5;
  object-fit: none;
  background-image: url("../../../assets/img/product-detail/cart.svg");
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
}

.inactive {
  cursor: not-allowed !important;
}
.pwiw-input-helper-text {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  font-style: italic;
}

.error-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff004e;
  display: flex;
  align-items: center;
}

.error-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  background-image: url("../../../assets/img//product-detail/error-icon.svg");
  margin-left: 25px;
  margin-right: 10px;
}

.accepted-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #00ff6f;
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.product-image {
  width: 100%;
  max-width: 450px;
  height: 450px;
}

.product-image-container {
  margin-bottom: 30px;
}

@media only screen and (min-width: 800px) {
  .product-image-container {
    padding-left: 80px;
    padding-right: 60px;
    padding-top: 30px;
    width: 600px;
    height: 100%;
  }
  .product-content {
    padding-left: 10;
  }
}

@media only screen and (max-width: 992px) {
  .priceModal {
    width: 85%;
  }
}

.tab-title-container {
  width: 100%;
  display: flex;
  border-bottom: 0px;
}

.tab-title {
  width: 500px;
  height: 82px;
  background-color: #000000;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.tab-title:hover {
  box-shadow: 0px 5px 9px 0 rgba(0, 0, 0, 0.16);
  color: #ffc107;
}

.tab-title-selected {
  width: 500px;
  height: 82px;
  background-color: #ffffff;
  border-top: solid 1px #707070;
  border-left: solid 1px #707070;
  border-right: solid 1px #707070;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.seperator {
  width: 4px;
  height: 22px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #b4b4b4;
  margin-left: 10px;
  margin-right: 10px;
}

.what-is-price-rate {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9baed4;
  cursor: pointer;
}

.product-detail-row {
  display: flex;
  margin-bottom: 10px;
  align-items: baseline;
}

.seller-profile-pic {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  object-fit: scale-down;
  background-position: center center;
  margin-left: 20px;
}

.seller-profile-text-row {
  display: flex;
  width: 80%;
  margin-bottom: 8px;
}

.attribute-text-seller {
  height: 25px;
  min-width: 150px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  text-transform: capitalize;
}

.seller-active-tag {
  width: 73px;
  height: 22px;
  background-color: #00ff6f;
  font-size: 14px;
  text-align: center center;
  color: #ffffff;
  padding: 3px 14px 3px 14px;
  margin-top: 5px;
}

.drop-shadow-custom {
  -webkit-box-shadow: 0px 7px 25px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 7px 25px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 7px 25px -20px rgba(0, 0, 0, 0.75);
}

.grey-container {
  min-height: 300px;
  border-radius: 3px;
  border: solid 0.3px #707070;
  background-color: #fbfbfb;
  margin-bottom: 40px;
}

.details-container-padding {
  padding: 30px 20px 10px 50px;
}

.product-details-title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #0a0a0a;
  margin-bottom: 30px;
}

.seller-name-title {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 950;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -2.1px;
  text-align: left;
  color: #000000;
}

.view-seller-profile {
  width: 130px;
  height: 30px;
  background-color: #00ff6f;
  color: #ffffff;
  border: none;
}

.image-detail-holder {
  width: 70%;
  display: flex;
  justify-content: center;
}

.digital-product-image {
  width: 40%;
  /* height: 500px; */
}
.column-gap {
  column-gap: 70px;
}

.product-content {
  padding-left: 10;
  width: "100%";
}
