.main-account-details {
  margin-top: 2rem;
}

.account-details-ul {
  display: flex;
  padding: 0 3rem !important;
  justify-content: space-between;
}

.account-details-ul li {
  color: #000000 !important;
  list-style: none;
}

.main-container{
  padding: 3rem 2rem;
}


@media only screen and (max-width: 425px) {
  .main-container{
    padding: 2rem 1rem !important;
  }
  .account-details-ul{
    padding: 0 !important;
  }
}
