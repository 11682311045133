.cart-entry-row {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: solid 0.3px #707070;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 800;
    color: #000000;
    margin-bottom: 20px;
}

.cart-entry-row-image {
    width: 64px;
    height: 64px;
    object-fit: cover;
    margin-right: 5%;
}

.cart-entry-row-product-name-section {
    display: flex;
    align-items: center;
    width: 300px;
}

.cart-entry-seperator {
    width: 1px;
    height: 40px;
    background-color: black;
}

.cart-entry-close-icon {
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.cart-entry-quantity-plus {
    width: 14px;
    height: 14px;
    object-fit: scale-down;
    cursor: pointer;
}

.cart-entry-quantity-minus {
    width: 14px;
    height: 14px;
    object-fit: scale-down;
    cursor: pointer;
}

.cart-entry-quanttity-count {
    width: 30px;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

@media screen and (max-width: 700px) {
    .cart-entry-hide-on-mobile {
        display: none;
        visibility: hidden;
    }
}

@media screen and (min-width: 700px) {
    .cart-entry-show-on-mobile {
        display: none;
        visibility: hidden;
    }
}