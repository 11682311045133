/* Base styles and variables */
:root {
  --primary-color: #EEAC1A;
  --primary-dark: #EEAC1A;
  --secondary-color: #f8fafc;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --card-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --transition-base: all 0.3s ease;
}

/* Page wrapper */
.media-page-wrapper {
  background: linear-gradient(135deg, #f8fafc 0%, #e2e8f0 100%);
  min-height: 100vh;
  padding: 2rem 0;
}

/* Header section */
.media-heading {
  text-align: center;
  font-weight: bold;
  padding: 1.5rem 0;
  margin-top: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #f3f4f6;
}

.media-heading h1 {
  font-size: 2.5rem;
  color: var(--text-primary);
  font-weight: 700;
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--primary-dark) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Card grid container */
.media-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 280px);
  gap: 1.5rem;
  padding: 1rem;
}


/* Media card */
.media-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  box-shadow: var(--card-shadow);
  transition: var(--transition-base);
  cursor: pointer;
}

.media-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Media card thumbnail */
.media-card-thumbnail {
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
}

/* Card content */
.media-card-content {
  padding: 1.5rem;
}

.media-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.media-card-info {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

/* Loading state */
.loading-message {
  text-align: center;
  padding: 4rem;
  color: var(--text-secondary);
}


@media (max-width: 426px) {
  .media-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .media-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 340px);
    gap: 1.5rem;
    padding: 1rem;
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
  }
}


@media (min-width: 1024px) {
  .media-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    gap: 1.5rem;
    padding: 1rem;
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
  }
}