/* General styles */
body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.8;
  font-size: 16px;
  background-color: #f1f5f9;
  scroll-behavior: smooth;
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 3vw 5vw;
  background-color: #f3f4f6;
  
}

.about-hero {
  text-align: center;

    background-color: white;
      padding: 2rem;
      border-radius: 12px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.about-hero h1 {
  color:#EEAC1A;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.about-stats {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;

      background-color: white;
        padding: 2rem;
        border-radius: 12px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.stat-item {
  text-align: center;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  min-width: 200px;
  color: white; 
  background-color: black;
}

.stat-number {
  color: #EEAC1A;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.team-section {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.team-image {
  width: 60%;
  height: 60vh;
  border-radius: 12px;
  display: block;
  /* Ensures it behaves like a block element */
  margin: 0 auto;
  /* Centers it horizontally */
}

.mission-section {
  background-color: black;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  text-align: center;
  color: white;
}

.highlight-text {
  color: #EEAC1A;
  font-weight: 600;
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 1.8rem;
  }

    .team-image {
      width: 100%;
      height: 50vh;
      border-radius: 12px;
    }

  .stat-item {
    min-width: 150px;
  }

  .stat-number {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .about-container {
    padding: 2vw 3vw;
  }

  .stat-item {
    min-width: 100%;
  }
}