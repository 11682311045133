.open-close-verify {
  font-size: 13px;
  color: #3f3f3f;
  padding-left: 28px;
}

h4 {
  font-weight: 500;
  line-height: 1.1;
}
.event-button {
  width: 200px;
  background-color: lightgray;
  color: black;
}

.update-button {
  display: inline-block;
  margin: 0 auto;
  color: #027cd5;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 15px 0;
  outline: 0;
  text-decoration: none;
}

h3 {
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.saveB {
  color: white;
}

.bank-terms {
  color: red;
}

.text-area-store {
  height: 170px;
}

.star {
  color: red;
}

.switch {
  float: right !important;
}

/* event styling*/
.custom-button {
  background-color: black;
  color: white;
  font-size: 16px;
  width: 95%;
  height: 40px;
  margin: 0 5px;
}

/* main event button*/
.custom-event-button {
  background-color: black;
  color: white;
}
.custom-event-button:hover {
  background-color: black;
  color: white;
}
/* pop up */

.label-input-container {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.label-container {
  flex: 1;
  margin-right: 1rem;
}

.input-container {
  flex: 2;
}

/* image field in popUp */

.image-dropdown {
  display: flex;
  align-items: center;
}

.image-dropdown .input-container {
  position: relative;
  border: 1px solid #ced4da;
}

.image-dropdown .upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 30px;
}

.image-dropdown .upload-icon {
  cursor: pointer;
}
.image-dropdown .upload-icon i {
  color: grey;
  font-size: 24px;
}

.image-dropdown .file-input {
  display: none;
}

.image-dropdown .upload-text {
  font-size: 1vw;
  color: #555;
  margin-top: 5px;
}
/* Event card*/

.card-row {
  margin-top: 20px;
  /* justify-content: space-between; */
}

.event-card {
  /* width: 250px; /* Set the fixed width for the card */
  /* height: 350px; */
  width: 100%;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.imageContainer {
  position: relative;
  display: inline-block; /* Ensures the container wraps around the image */
}
.imageContainer-text-overlay {
  position: absolute;
  top: 69%;
  left: 0%;
  transform: translate(5%, 100%);
  background-color: rgba(255, 255, 255, 0.4);
  padding: 10px;
  font-size: 16px;
  color: black;
  z-index: 1;
}
.image-blur-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Adjust height to control the blur area */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  filter: blur(10px); /* Adjust blur radius as needed */
  z-index: 1;
}
.imageContainer-button-overlay {
  position: absolute;
  top: 69%;
  left: 69%;
  transform: translate(5%, 100%);
  background-color: orange;
  padding: 10px;
  font-size: 12px;
  color: black;
  z-index: 1;
}
.imageContainer-button-overlay1 {
  position: absolute;
  top: 69%;
  left: 69%;
  transform: translate(5%, 100%);
  background-color: orange;
  padding: 10px;
  font-size: 12px;
  color: black;
  z-index: 1;
  width: 100px;
  margin-left: -30px;
}
@media screen and (max-width: 705px) {
  .skylight-dialog {
    /* width: 90% !important; */
    width: 280px !important;
    margin: -190px auto !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .card-body {
    video {
      width: 355px;
      height: auto;
    }
  }
}

/** Event card styling (Apply only on small screens )*/
@media only screen and (max-width: 768px) {
  .event-card-customer-account {
    justify-content: center;
  }
}
