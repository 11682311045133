.row-gap {
  row-gap: 30px;
  overflow: auto;
  height: 100%;
}

.digital-card-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.card-img-margin {
  margin-bottom: 20px;
  height: 200px;
}

.card-title-margin {
  margin-bottom: 0;
}
.download-box-card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-bottom: 10px;
}

.download-card-text {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
