.product-preview-container-image-wrapper {
  height: 201.3px;
  width: 194.5px;
  background-color: #d9d9d9;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 13px;
}

.product-preview-container {
  width: 218px;
  height: 313.29px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
  margin-top: 30px;
  margin-right: 30px;
  border-radius: 13px;
}

.product-preview-container-image {
  width: 100%;
  height: 100%;
  border-radius: 13px;
}

.product-preview-add-button {
  width: 25%;
  height: 127px;
  object-fit: contain;
  float: right;
}
.even-ticket-button {
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #000000;
  color: white;
  margin-top: 30px;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 500;
}
.product-detail-view-button {
  background-color: #000000;
  color: white;
  padding: 5px 8px;
  margin-top: -8px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
}
.product-preview-name-text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.5px;
  text-align: left;
  color: #000000;
  margin-left: 10px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-preview-brand-text {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #9b9b9b;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 16px;
  margin: 7px 10px;
}
