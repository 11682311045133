/* Finance container styles */
.finance-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 100vw;
  padding: 3vw 5vw;
  background-color: #f3f4f6;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.finance-content {
  display: flex;
  gap: 20px;
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  align-items: center;
  flex-direction: row;
}

.finance-image {
  width: 50%;
  height: auto;
  /* Change to auto for better responsiveness */
  border-radius: 12px;
  max-height: 60vh;
  /* Limit max height */
  /* Maintain aspect ratio */
}

.finance-text {
  flex: 1;
  padding: 0 2rem;
}

h2 {
  font-size: 1.5em;
  /* Reduced font size */
  margin-bottom: 0.5em;
  color: #111827;
  font-weight: 700;
}

.finance-text p {
  font-size: 1.1em;
  color: #4b5563;
  margin-bottom: 1.2em;
}

.money-coach {
  font-weight: bold;
  color: #EEAC1A;
  background-color: black;
  padding: 0.2em 0.5em;
  border-radius: 5px;
}

/* Alternate image-text layout for larger screens */
@media (min-width: 1024px) {
  .finance-content:nth-child(even) {
    flex-direction: row-reverse;
  }
}

/* Responsive Styles */
@media (max-width: 1023px) {
  .finance-content {
    flex-direction: column;
    text-align: center;
  }

  .finance-image {
    width: 100%;
    margin-bottom: 20px;
    height: 55vh;
    /* Limit max height */
  }

  .finance-text {
    padding: 0;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 10px;
  }

  h2 {
    font-size: 1.2rem;
    /* Reduced font size for smaller screens */
  }

  .finance-text p {
    font-size: 0.75rem;
  }

  .finance-image {
    width: 100%;
    /* Change to auto for better responsiveness */
    height: 35vh;
    /* Limit max height */
  }
}

@media (min-width: 1920px) {
  h2 {
    font-size: 2em;
    /* Reduced font size */
  }

  .finance-text p {
    font-size: 1.3em;
  }
}

@media (min-width: 2560px) {
  h2 {
    font-size: 2.5em;
    /* Reduced font size */
  }

  .finance-text p {
    font-size: 1.5em;
  }
}