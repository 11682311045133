.table-bordered thead th, .table-bordered thead td{
  background-color: #2f353a;
  border-bottom-width: 2px;
  font-weight: unset;
  color: white;
}

.search-color{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fbc108;
}


#button2 {
  border:0px white;   
 
 background-color:white;
 -webkit-border-radius:40px/24px;
 -moz-border-radius:40px/24px;
 border-radius:40px/24px;
}