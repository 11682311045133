.coin-msg{
    margin-top: -82px;
    margin-left: 115px;
}
.coin-disp{
    margin-left: 340px;
    transform: scale(1);
    position: relative;
    bottom: -15px;
}
.coin-btn-disp{
    margin-left: 340px;
    transform: scale(1);
}

.coin-disp:hover{
    margin-left: 340px;
    transform: scale(1.01);
}
.coins-qty{
    color: #ffc101;
    font-size: 180%;
}

.item-text {
    display: inline-block;
    padding-bottom: 10px; 
}

/* DISPLAY */
.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexNullCenter {
    display: flex;
    align-items: center;
}
