.button-style{
    margin-left: 67%;
}

.table-bordered thead th, .table-bordered thead td{
    background-color: #2f353a;
    border-bottom-width: 2px;
    font-weight: unset;
    color: white;
    table-layout:fixed!important;
}

.search-color{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #fbc108;
}

.mt-4, .my-4 {
    margin-top: -0.5rem !important;
}
/* .product-name-ellipses{
    border: 1px solid black;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
} */


