/* Base header styles */
.header-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.header-background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    145deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 1;
}

/* Content Section */
.pf-header-content {
  width: 100%;
  padding: clamp(1rem, 4vw, 3rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: clamp(0.75rem, 2vw, 2rem);
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Logo Section */
.logo-section {
  display: flex;
  align-items: center;
}

.logo-image {
  height: clamp(40px, 5vw, 50px);
  width: auto;
  transition: transform 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.05);
}

/* Navigation Section */
.nav-section {
  display: flex;
  align-items: center;
  gap: clamp(1rem, 2vw, 1.5rem);
}

/* Menu Button */
.menu-button {
  font-size: clamp(24px, 4vw, 32px);
  color: #EEAC1A;
  cursor: pointer;
  transition: all 0.3s ease;
  width: clamp(40px, 6vw, 48px);
  height: clamp(40px, 6vw, 48px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

/* Mobile Menu */
.mobile-menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #1a1a1a 0%, #2c2c2c 100%);
  z-index: 1000;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(10px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mobile-menu.active {
  right: 0;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
}

/* Menu Container */
.menu-container {
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: clamp(1rem, 4vw, 2rem);
  overflow-y: auto;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 2vw, 1.5rem);
  padding: clamp(1.5rem, 3vw, 2rem);
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  width: 100%;
  max-width: 400px;
  margin: 4rem auto;
}

.menu-items a {
  color:#EEAC1A !important;
  text-decoration: none;
  font-size: clamp(1rem, 2vw, 1.25rem);
  padding: clamp(0.75rem, 2vw, 1rem) clamp(1rem, 2vw, 1.5rem);
  border-radius: 12px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.menu-items a:hover {
  
  background-color: #2c2c2c !important;
}

/* .CandE-link:hover,
.contact-link:hover {
    background-color: white;
    transition: background-color 0.3s ease;
} */

/* Centered Text */
.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: -9999;
  width: min(90%, 800px);
  padding: clamp(1rem, 4vw, 2rem);
}

.header-title {
  font-size: clamp(2rem, 5vw, 4rem);
  font-weight: bold;
  color: #EEAC1A;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInDown 0.8s ease-out;
}

/* Language Selector */
.language-selector {
  display: flex;
  align-items: center;
  color: #EEAC1A;
  cursor: pointer;
  padding: clamp(0.4rem, 1vw, 0.5rem) clamp(0.75rem, 1.5vw, 1rem);
  border-radius: 88px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 480px) {
  .mobile-menu {
    width: 100%;
  }

  .menu-container {
    padding: 1rem;
  }

  .menu-items {
    margin-top: 3rem;
    padding: 1rem;
  }

  .close-menu-button {
    top: 1rem;
    right: 1rem;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .mobile-menu {
    width: 100%;
  }

  .menu-items {
    max-width: 350px;
  }
}

@media (min-width: 769px) {
  .mobile-menu {
    width: min(400px, 50vw);
  }
}

/* Height-based media queries */
@media (max-height: 600px) {
  .menu-container {
    padding-top: 4rem;
  }

  .menu-items {
    margin: 2rem auto;
  }

  .menu-items a {
    padding: 0.5rem 1rem;
  }
}

/* Close button */
.close-menu-button {
  position: fixed;
  top: clamp(1rem, 3vw, 2rem);
  right: clamp(1rem, 3vw, 2rem);
  font-size: clamp(1.5rem, 3vw, 2rem);
  color: #EEAC1A;
  background: rgba(255, 255, 255, 0.1);
  width: clamp(40px, 6vw, 48px);
  height: clamp(40px, 6vw, 48px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1001;
}

.lang-menu{
background-color: aliceblue;
}