.price-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .price-dialog-container {
    background: var(--secondary-bg);
    border-radius: 16px;
    box-shadow: 0 15px 35px -10px rgba(238, 172, 26, 0.2);
    width: 90%;
    max-width: 500px;
    padding: 2rem;
    text-align: center;
    border: 1px solid rgba(238, 172, 26, 0.2);
  }
  
  .price-dialog-title {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .price-dialog-message {
    color: white;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .price-dialog-close-btn {
    width: 100%;
    padding: 1rem;
    background: var(--primary-color);
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 1.125rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition-base);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 8px 25px rgba(238, 172, 26, 0.4);
  }
  
  .price-dialog-close-btn:hover {
    background: var(--text-primary);
    color: var(--primary-color);
    transform: translateY(-4px);
    box-shadow: 0 12px 30px rgba(238, 172, 26, 0.5);
  }