.subtitle {
    margin: 0 0 2em 0;
  }
  
  .fancy {
    line-height: 0.5;
    text-align: center;
  }
  
  .fancy span {
    display: inline-block;
    position: relative;
  }
  
  .fancy span:before,
  .fancy span:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid black;
    top: 0;
    width: 70px;
  }
  
  .fancy span:before {
    right: 100%;
    margin-right: 15px;
  }
  
  .fancy span:after {
    left: 100%;
    margin-left: 15px;
  }
  
  .kep-login-facebook.metro {
    background-color: #4c69ba;
    text-transform: lowercase;
    border-radius: 4px;
    display: block;
    color: rgb(255, 255, 255);
    width: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    font-family: Roboto;
    margin-left: -20px;
  }
  
  .google_btn {
    background: rgb(209, 72, 54);
    color: rgb(255, 255, 255);
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: bold;
    font-family: Roboto;
    display: inline-block !important;
  }
  
  .text-right {
    margin-top: -12px;
  }
  
  .text-checkbox {
    margin-top: -25px;
  }
  
  .twitter-btn {
    background-color: #4c69ba;
    text-transform: lowercase;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    font-family: Roboto;
    border: 0px;
    display: inline-block !important;
  }
  
  .row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
  }
  
  .login-background {
    background: #eeac1a;
    /* fallback for old browsers */
    background-image: url(../../../assets/img/signin-background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 95% 15%;
    height: auto;
    & .container {
      max-width: 1400px;
      max-height: 630px;
      width: 100%;
    }
  }
  .main-position {
    position: absolute;
  }
  
  .login-tagline {
    height: 26px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -1.2px;
    text-align: left;
    color: #000000;
    display: flex;
    justify-content: center;
  }
  
  @media only screen and (max-width: 1024px) {
    .google_btn,
    .twitter-btn {
      width: 114px !important;
      display: inline-block;
    }
    .login-background {
      & .container {
        max-height: 550px;
      }
    }
    .login-tagline {
      font-size: 16px;
    }
    .login-main-wrapper {
      height: 700px;
      width: 100%;
      background-color: #fbc218;
    }
  }
  @media only screen and (max-width: 768px) {
    .google_btn,
    .twitter-btn {
      width: 150px !important;
      display: inline-block;
    }
    .login-background {
      & .container {
        max-height: 550px;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .google_btn,
    .twitter-btn {
      width: 250px !important;
      margin-bottom: 1rem;
    }
    .subtitle {
      font-size: 13px;
    }
    .text-right {
      text-align: left !important;
      margin-top: 4%;
    }
    .login-main-wrapper {
      height: 750px;
    }
  }
  
  @media only screen and (max-width: 375px) {
    .google_btn,
    .twitter-btn {
      width: 200px !important;
      margin-bottom: 1rem;
    }
  }
  
  @media only screen and (max-width: 320px) {
    .google_btn,
    .twitter-btn {
      width: 150px !important;
      margin-bottom: 1rem;
    }
    .fancy span:before,
    .fancy span:after {
      width: 30px;
    }
  }
  
  .welcome-back {
    width: 265px;
    height: 31px;
    font-family: Roboto;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: -1.68px;
    text-align: left;
    color: #000000;
    margin-top: 150px;
  }
  
  .open-the-door {
    width: 483px;
    height: 50px;
    font-family: Roboto;
    font-size: 52px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -3.2px;
    text-align: left;
    color: #000000;
  }
  
  .you-have-the-key {
    width: 648px;
    height: 30px;
    font-family: Roboto;
    font-size: 48px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -2.44px;
    text-align: left;
    color: #000000;
    margin-top: 10px;
  }
  
  @media (max-width: 991px) {
    .hide-on-mobile {
      display: none;
    }
  }
  
  .sign-in-button {
    width: 240px;
    height: 52px;
    border: solid 1px #000000;
    background-color: #000000;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }
  
  .login-input {
    width: 472px;
    height: 40px;
    border-radius: 7px;
    border: solid 0.5px #707070;
    background-color: #f8f8f8;
  }
  
  .signin-tab-text {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .register-tab-text {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .title-login {
    /* width: 200px;
    height: 49px;
    font-family: Roboto;
    font-size: 36px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -2.05px;
    text-align: left;
    color: #000000; */
  
    font-size: 36px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    font-family: Roboto;
    color: #000000;
    display: flex;
    justify-content: center;
  }
  .fixed-header {
    position: fixed;
    top: 0;
    z-index: 1;
  }
  