.invoice-button {
    display: block;
    width: 200px;
    height: 42px;
    text-align: center;
    color: white !important;
    background-color: #ffc107;
    border-color: white;
    padding: 10px;
    float: right;
    border-radius: 5px;
    font-weight: bold;
    text-emphasis-color: white;
    box-shadow: 10px 10px 5px rgb(226, 222, 222);
}


.button-hollow {
    display: block;
    width: 200px;
    height: 42px;
    color: #1779ba !important;
    background-color: white;
    border-color: black !important;
    border: lightgray solid !important;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    text-emphasis-color: white;
    box-shadow: 10px 10px 5px rgb(226, 222, 222);
}

.invoice-name {
    font-weight: 200;
    font-size: 2rem;
    color: #1779ba;
    font-family: 'Montserrat', sans-serif;
}

.billing-invoice {
    font-size: 1rem;
    color: #1779ba;
    font-family: 'Montserrat', sans-serif;
}

.invoice-qty {
    margin-left: 30px
}

.invoice-address {
    font-size: inherit;
    line-height: 1.6;
    text-rendering: optimizeLegibility
}

.card-shadow {
    box-shadow: 10px 10px 5px rgb(226, 222, 222);
}

.invoice-desc {
    padding: 5px;
}

@media only screen and (min-width: 768px) {
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .invoice-button {
        width: 100%;
    }
}

@media only screen and (min-width: 425px) {
    .invoice-button {
        float: left;
    }
}

@media only screen and (min-width: 375px) {
    .invoice-button {
        float: left;
    }
}

@media only screen and (min-width: 320px) {
    .invoice-button {
        float: left;
    }
}

@-moz-document url-prefix() {
  .firefox-margin {
    margin-top: 10%;
  }
}

@media print{
    .noprint{
        display:none;
    }
 }

