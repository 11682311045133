.icon-bell {
  font-size: 20px;
  color: #fbc108;
}

.userName {
  color: white;
}

.app-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  z-index: 1020;
  color: #ffffff;
  background: #000000;
  flex: 0 0 60px;
  -ms-flex: 0 0 50px;
}
/* 
.banner {
	height: 750px;
} */

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #e91e63;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 30px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 30px;
  color: black;
}

.footer-part {
  background-color: #000000 !important;
  height: auto !important;
  width: 100%;
}

.text-color {
  color: white;
}

.image {
  position: relative;
  width: 100%;
}

.text-content {
  position: absolute;
  color: white;
  top: 75px;
  left: 0;
  width: 100%;
}

.thumbnail-text1 {
  position: absolute;
  font-size: 150%;
  color: white;
  top: 250px;

  width: 100%;
}
.thumbnail-text2 {
  position: absolute;
  color: white;
  top: 260px;
}
.title-color {
  color: #fbc108;
}

.thumbnail-text3 {
  position: absolute;
  font-size: 1.3rem;
  color: white;
  top: 370px;

  width: 100%;
}

.search-content {
  position: absolute;
  color: white;
  top: 80%;

  height: 10%;
  width: 100%;
}

.thumbnail-text4 {
  position: absolute;
  color: white;
  top: 490px !important;
  height: 10%;
  width: 100%;
}

.thumbnail-text4 .form-control {
  padding: 0.7rem 0.75rem;
}

.top {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
  z-index: 0;
  height: 75%;
  margin-bottom: 6%;
}

.cat-sidebar {
  border-left: 1px solid #d9d4cf;
  border-bottom: 1px solid #d9d4cf;
}

.product-search {
  color: #23282c;
  background-color: #ffc107;
  border-color: #ffc107;
  padding: 0.375rem 0.75rem;
}

.catName:hover {
  font-size: 150%;
  color: #fbc108;
}
.cat-results {
  width: 100%;
  margin-right: 4px;
}

.cat-wrapper {
  display: flex;
  background-color: white;
  width: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
}
.navB,
.navB a,
.navB ul,
.navB li,
.navB div,
.navB form,
.navB input {
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  border: none;
  outline: none;
}

.navB a {
  text-decoration: none;
}

.navB li {
  list-style: none;
}
.filter-header {
  padding: 10px 20px;
  border-top: 1px solid #d9d4cf;
  color: #8d8782;
  position: relative;
}

.navB {
  display: block;
  position: relative;
  cursor: default;
  z-index: 500;
}
.btn-link {
  cursor: pointer;
}

.navB > li {
  display: block;
  float: left;
}
.filter-header-title {
  color: #23282c;
  font-size: 0.875rem;
  margin: 0;
  line-height: 30px;
  padding-right: 30px;
  cursor: pointer;
  text-transform: uppercase;
}

.filter-header-count {
  color: #8d8782;
  border-radius: 50%;
  border: 1px solid #beb6ae;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 29px;
  text-align: center;
  float: right;
  top: 10px;
}

.navB > li > a {
  position: relative;
  display: block;
  z-index: 510;
  height: 54px;
  padding: 5px 30px;
  line-height: 54px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: black;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
  background: white;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.navB > li:hover > a {
  background: #fbc108;
}

.navB > li:first-child > a {
  border-radius: 3px 0 0 3px;
  border-left: none;
}

.cat-utility-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #d9d5cf;
  border-top: 1px solid #d9d5cf;
}

.cat-utility-count {
  width: 100%;
  border-right: 1px solid #d9d4cf;
  line-height: 1.5em;
  padding: 7px 20px;
}

.cat-utility-sort {
  height: 33px;
  border: 0;

  cursor: pointer;
  background-color: white;
}

.navB > li > div {
  position: absolute;
  display: block;
  width: 100%;
  top: 55px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  font-weight: bold;
  overflow: hidden;
  background: #fff;
  border-radius: 0 0 3px 3px;
  border: 2px solid lightgray;

  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
}
.navB > li:hover > div {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  cursor: pointer;
}

.navB .nav-column {
  float: left;
  width: 20%;
  padding: 1%;
  color: #fff;
  margin-top: -20px;
}

.radio-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;
  font-size: 1rem;
  color: #23282d;
}

.radio-cursor {
  cursor: pointer;
}

.navB .nav-column h5 {
  margin: 13px 0 10px 0;
  line-height: 18px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #372f2b;
}

.navB .nav-column h3.orange {
  color: #ff722b;
}

.navB .nav-column li {
  display: block;
  line-height: 26px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #888888;
  margin-left: 22px;
}

.navB .nav-column li a:hover {
  color: #666666;
}
.cat-results-header {
  padding: 20px;
  border-bottom: 1px solid #d9d4cf;
  background-color: white;
}

.rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.cat-sidebar input.cat-search {
  display: flex;
  border: 0;
  font-style: italic;
  height: 52px;
  width: 100%;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org…2C12%2C5.51%2C5.51%2C0%2C0%2C1%2C1%2C6.5Z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
  padding-left: 46px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 20px 18px;
}

.cat-search-form {
  border-top: 1px solid #d9d4cf;
}

.product-thumb-divH {
  height: 310px;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 0px;
  border: 1px solid #d9d4cf;
}
.product-thumb-divH:hover {
  height: 310px;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 10px;
  border: 1px solid #d9d4cf;
  border: 1px solid #fbc108;
}

.text-family {
  font-family: "Open Sans,Arial,Helvetica,sans-serif";
}

.loader {
  font-size: 1.875rem;
  padding-left: 48%;
}

.a-cat {
  color: #372f2b !important;
  cursor: pointer;
}
.all-products {
  margin-right: 50px !important;
  margin-left: 50px !important;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.load-more {
  margin-top: 2%;
  margin-bottom: 2%;
}

.product-thumb-labelH {
  margin-right: 138px;
}

.product-thumb-titleH {
  float: left;
  font-family: initial;
  margin: 0 0;
  padding: 10px;
  width: 219px;
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  text-overflow: ellipsis;
}

.product-thumb-priceH {
  font-size: 14px;
  margin-right: 226px;
}
.product-priceH {
  margin-top: 10%;
  font-size: 17px;
  margin-left: 10px;
}
.strike-price {
  margin-left: 10px;
}

.product-byH {
  float: left;
  margin-left: 10px;
}
.product-brandH {
  margin-right: 150px;
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 18px;
  }
  #cms-category {
    font-size: 0.9rem;
    cursor: pointer;
  }
  #cms-subCategory {
    font-size: 12px;
  }
  #currency-dropdown {
    width: 65%;
  }
}
@media only screen and (max-width: 425px) {
  p {
    font-size: 16px;
  }
  #cms-category {
    font-size: 0.8rem;
    cursor: pointer;
  }
  #cms-subCategory {
    font-size: 13px;
  }
  #currency-dropdown {
    width: 50%;
    margin-top: 16px;
  }
  .footer-part .text-color br {
    display: none;
  }
  .footer-part .text-color {
    margin-top: 1rem;
  }
  .thumbnail-text2 {
    font-size: 285%;
  }
  .paraLineOne_Content {
    display: none;
  }
}
@media only screen and (max-width: 375px) {
  #cms-category {
    font-size: 0.8rem;
    cursor: pointer;
  }
  #cms-subCategory {
    font-size: 12px;
  }
  /* #yinn-logo {
    margin-top: -65px;
  } */
  #currency-dropdown {
    margin-top: 16px;
    width: 57%;
  }
}
@media only screen and (max-width: 320px) {
  .thumbnail-text2 {
    font-size: 265%;
  }
  #currency-dropdown {
    width: 71%;
  }
}

/* 
@media only screen and (max-width: 1900px){
  .thumbnail-text2{
    font-size: 265%;
  }
  #currency-dropdown{
    width: 71%;
  }
} */

.dropdown-item:hover {
  background-color: #ffc107;
  border-color: #ffc107 !important;
}

.banner-image {
  background-image: url("../../../assets/img/zebra.jpg");
  width: 100%;
  height: 900px;
  object-fit: contain;
  vertical-align: middle;
}

.banner-column {
  padding-left: 0;
  padding-right: 0;
}

.banner-left-container {
  width: 100%;
  background-color: #f5f5f5;
}

.shop-online-with-yinn .text-style-1 {
  font-size: 49px;
  font-weight: 900;
  letter-spacing: -3.45px;
}

.search-input {
  position: absolute;
  width: 80% !important;
  height: 48px;
  background-color: #ffffff;
  border-radius: 0;
  border: none;
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #bfbfbf;
}

.search-button {
  width: 100%;
  height: 46px;
  border: solid 1px #000000;
  background-color: #000000;
}

.search-icon {
  color: #ffffff;
}

body {
  overflow-x: hidden !important;
  background-color: white;
}

.hero-area {
  height: 100vh;
}

.hero-area .search-btn-group {
  width: 52px;
  border: 1px solid #fff;
  border-left: none;
}

.hero-area .left-section {
  color: #fff;
}

.hero-area .input-group {
  width: 324px;
}

.hero-area .name {
  color: #fffd00;
}

.hero-area .hero-img {
  display: none;
}

.body-area {
  margin-top: 5rem;
}

.body-area .title-text {
  color: #000000;
  font-weight: 100;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
}

.image-container .centered {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 8px;
}

.image-container .overlay-img {
  background-image: url("../../../assets/img/home/overlay-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.6);
  height: 500px;
  width: 100%;
}

.image-container .overlay-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  background-size: 100% 100%;
}

.image-container .centered .header-font {
  font-weight: 100;
}

.image-container .centered p {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 1px;
}

.message .discover-text {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.font-400 {
  font-weight: 400;
}

.header-font {
  font-size: 30px;
}

.p-large {
  font-size: 18px;
}

.p-small {
  font-size: 14px;
}

.button-text {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .hero-area .input-group,
  .hero-area .form-class {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .image-container .overlay-img {
    height: 400px;
  }
}

@media screen and (max-width: 420px) {
  .image-container .overlay-img {
    height: 425px;
  }
}

@media screen and (min-width: 480px) {
  .header-font {
    font-size: calc(30px + 6 * ((100vw - 480px) / 900));
  }

  .p-small {
    font-size: calc(14px + 2 * ((100vw - 480px) / 900));
  }
}
@media screen and (min-width: 1700px) {
  .header-font {
    font-size: 43px;
  }

  .p-large {
    font-size: 20px;
  }

  .p-small {
    font-size: 17px;
  }

  .button-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .hero-area .search-bar {
    margin-bottom: 19.5rem;
  }

  .hero-area .hero-img {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: 80%;
  }

  .image-container .centered {
    width: 90%;
  }
}

@media screen and (min-width: 992px) {
  .hero-area .search-bar {
    margin-bottom: 19.5rem;
  }

  .details-carousel {
    display: block;
  }

  .hero-area .hero-img {
    display: block;
    position: absolute;
    left: 40%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: 60%;
  }

  .image-container .centered {
    width: 70%;
  }

  .hero-area .input-group {
    width: 30vw;
  }
}

@media screen and (min-width: 1199px) {
  .image-container .centered {
    width: 58%;
  }

  .message .discover-text {
    width: 70%;
  }

  .hero-area .search-bar-padding {
    padding-right: 8.25rem !important;
  }

  .hero-area .content-padding {
    padding-left: 4.75rem !important;
  }

  .hero-area .content-width {
    width: 79%;
  }
}

.mt-4 {
  margin-top: 2rem !important;
}

.black-bg-btn {
  background-color: #000000;
  color: #fff;
  font-weight: normal;
  border-radius: unset;
  box-shadow: none;
}

.black-bg-btn:hover {
  color: #fff;
}

.black-bg-btn:focus {
  box-shadow: none;
}
