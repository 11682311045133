.button-color {
  background-color: #f6c803;
  border-color: #f6c803;
  color: white;
  border-radius: 5px;
}
.button-color :hover {
  background-color: #8bfd00;
}
.div-style {
  border: 0.5px solid rgb(164, 162, 162);
  border-radius: 5px;
}
.link-style {
  color: #f6c803;
  cursor: pointer;
}
.link-style :hover {
  color: #428bca;
}
.view-span {
  background-color: #f6c803;
  color: white;
  font-size: 15px;
}
.order-id-text {
  font-size: 18px;
}
.date-width {
  min-width: 120px;
}
.shipping-width {
  min-width: 110px;
}
.order-product-text {
  font-weight: bold;
}
.total-td-text {
  font-weight: 400;
}
.order-view-text {
  font-weight: 600;
}
.main-order-table {
  margin-top: 110px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}

.order-view-loader {
  margin-right: 400px;
  margin-top: 200px;
}

.bg-color {
  background-color: lightgray;
}

.textCenter {
  text-align: center;
}
.order-icons {
  border: 1px solid lightgray;
  height: 30px;
  width: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 4px;
  cursor: pointer;
}
.order-icons:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
@media (max-width: 480px) {
  .main-order-table {
    border-right: none;
  }
  .order-view-loader {
    margin-right: 150px !important;
  }
}
