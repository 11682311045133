.chat-margin {
  margin-top: 120px;
  background-color: #f0f3f5;
}
.message-container {
  height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
  overflow-x: auto;
}
.support-detail-view-icons {
  border: 1px solid lightgray;
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.support-detail-view-icons:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.ticket-button-wrapper {
  width: 100%;
  height: auto;
  border: 1px solid lightgray;
  margin-bottom: 20px;
}
.ticket-box-wrapper {
  background-color: lightgray;
  height: 40px;
}
.ticket-box-wrapper p {
  display: flex;
  padding-top: 5px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 425px) {
  .chat-margin {
    margin-top: 25px;
    background-color: #f0f3f5;
  }
}
