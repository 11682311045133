/* qa.css */
.aqCard {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100vw;
}

.aq-heading {
  text-align: center;
  font-weight: bold;
  padding: 1.5rem 0;
  margin-top: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #f3f4f6;
  color: black;
}

.aqCard-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 0 auto;
  
}

.aqCard-heading {
  font-size: 1.5rem;
  font-weight: 700;
  color: #EEAC1A;
  background-color: white;
}

.aqCard-text {
  font-size: 1.1rem;
  color: #4b5563;
  line-height: 1.6;
}

/* Responsive styles */
@media (max-width: 767px) {
  .aqCard-container {
    padding: 1rem;
    width: 100%;
  }

  .aqCard-heading {
    font-size: 1.2rem;
  }

  .aqCard-text {
    font-size: 0.9rem;
  }
}