.coin-pacakge-wrapper {
  max-width: 100%;
  height: 100px;
  background-color: lightgray;
}

.coin-msg {
  margin-top: -82px;
  margin-left: 115px;
}

.coin-disp {
  margin-left: 340px;
  transform: scale(1);
  position: relative;
  bottom: -15px;
}

.coin-btn-disp {
  margin-left: 340px;
  transform: scale(1);
}

.coin-disp:hover {
  margin-left: 340px;
  transform: scale(1.01);
}

.coins-qty {
  color: #ffc101;
  font-size: 180%;
}

@media only screen and (max-width: 320px) {
  .coins-qty {
    color: #ffc101;
    font-size: 80% !important;
  }
  .coin-img {
    width: 70%;
  }
  .coin-message {
    font-size: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .coins-qty {
    color: #ffc101;
    font-size: 110%;
  }
  .coin-img {
    width: 70%;
  }
}

@media only screen and (max-width: 425px) {
  .coin-img {
    width: 70%;
  }
  .coin-pacakge-box {
    max-width: 300px !important;
  }
}

@media only screen and (max-width: 768px) {
  .coin-message {
    margin-top: 1rem !important;
  }
}

@media only screen and (max-width: 1024px) {
  .coin-message {
    margin-top: 2rem;
  }
}

.yinn-coin-top-bar {
  width: 100%;
  height: 120px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 8%;
  padding-right: 8%;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 900;
}

.packages-main-area {
  background-color: #ffffff;
  padding: 5%;
}

.yinn-coin-button {
  width: 170px;
  height: 45px;
  background-color: #000000;
  margin-right: 10px;
  color: #ffffff;
}

.coin-pacakge-box {
  max-width: 100%;
  height: 140px;
  border-radius: 2px;
  border: solid 0.3px #707070;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  overflow-x: auto;
}

.coin-input {
  width: 200px;
  height: 40px;
  border-radius: 7px;
  border: solid 0.5px #707070;
  background-color: #f8f8f8;
}

.coin-input-box {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
}

.coin-pacakge-box-selected {
  width: 440px;
  height: 140px;
  border-radius: 2px;
  border: solid 0.3px #00ff6f;
  background-color: #f4fdf8;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
}

.coin-img {
  width: 20%;
  height: 50px;
}

.coin-package-name {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 15px;
}

.coin-package-price {
  -webkit-text-stroke: 0.3px #707070;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
}
