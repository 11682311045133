
.local-footer{
    margin-top: 4% !important;
}

.validations{
    color: red;
    margin-top: -3%;
    /* margin-left: 27%; */
}

.password-validations{
    color: red;
    margin-top: -5%;
}

.row-validations {
        margin-bottom: 2%;
}