.shipping-form {
  /* min-height: 800px; */
  background-color: white;
  margin-bottom: 50px;
  padding-left: 9px !important;
}

.shipping-title {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-top: 45px;
}

.shipping-form-input-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.shipping-form-input-label {
  width: 120px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-right: 8px;
}

.shipping-form-input-input {
  width: 280px;
  height: 36px;
  border-radius: 2px;
  border: solid 0.3px #707070;
  background-color: #f8f8f8;
  padding-left: 5px;
}
.shipping-form-input-focus:focus-visible {
  border: 2px solid red !important;
  outline: none;
}
.order-status-input {
  width: 150px;
  height: 36px;
  border-radius: 2px;
  border: solid 0.3px #707070;
  background-color: #f8f8f8;
  padding-left: 15px;
}

.billing-Address-title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;

  display: flex;
  justify-content: space-between;
}

.billing-separator {
  width: 100%;
  height: 1px;
  background-color: #0a0a0a;
  margin-top: 10px;
  margin-bottom: 30px;
}

.checkout-next-button {
  width: 140px;
  height: 50px;
  background-color: #000000;
  border: none;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
}

.checkout-next-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 30px;
}

.payment-method-container {
  width: 250px;
  height: 85px;
  border-radius: 2px;
  border: solid 1px #000000;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 10px;
}

.payment-method-container-disabled {
  opacity: 0.2;
}

.payment-method-radio {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  margin-right: 8px;
}

.payment-method-select-area {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.payment-method-container-selected {
  width: 250px;
  height: 85px;
  border-radius: 2px;
  border: solid 1px #00ff6f;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 10px;
}

.payment-method-radio-selected {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  border-width: 1px;
  border-color: #00ff6f;
  border-style: solid;
  margin-right: 8px;
  background-color: #00ff6f;
}

.payament-modal-container {
  max-width: 890px;
  min-height: 510px;
  border-radius: 7px;
  box-shadow: 0 0 99px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}

.payament-modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payament-modal-title-container .title-header {
  display: flex;
  align-items: center;
}

.payament-modal-title {
  width: 424px;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.payment-modal-content {
  margin-top: 60px;
  padding: 0px 30px 0px 30px;
  margin-bottom: 70px;
}

.overlay-purchase-processing {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 999999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 425px) {
  .payament-modal-title {
    width: 200px !important;
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 768px) {
  .payament-modal-title {
    height: 37px;
  }
}
