ol.progtrckr {
  list-style-type: none;
  padding: 0;
  padding-left: 420px;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #CCCCCC;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #CCCCCC;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.payment-radio{
  margin-left: 2px
}

.payment-label-radio{
  margin-left: 2px
}
.lSize{
  font-size: 20px
}
.review-terms{
  font-size:10px;
}

.cod-order{
  color: #ffc107;
  margin-left: 33%;
  margin-top: -30%;
}
.cod-orderr{
  margin-left: 25%;
  margin-top: -20%;
}

.codd-order{
  margin-left: 34%;
  margin-top: -18%;
}

.cod-image{
  width: 15%;
  height:100%;
  margin-left:194px;
  margin-top: -5px;
}

.submit-order{
  color: white;
}

.input-border {
  border: 1px solid #c8ced2 !important;
}
/* .payment ~ div.footer-buttons {display: none} */

/* success card css */
.coins-cardcard {
  position: relative;
  top: 110px;
  width: 320px;
  display: block;
  text-align: center;
  margin: auto;
  padding-left: 310px;
  font-family: 'Source Sans Pro', sans-serif;
}

#upper-side {
  padding: 3em 8em;
  background-color: #8BC34A;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

#checkmark {
  font-weight: lighter;
  fill: #fff;
  margin: -3.5em auto auto 20px;
}

#status {
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1em;
  margin-top: -.2em;
  margin-bottom: 0;
}

#lower-side {
  padding: 2em 1em 4em 8em;
  background: #e4d9d9;
  display: block;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#message {
  margin-top: -.5em;
  color: #757575;
  letter-spacing: 1px;
}

#contBtn {
  position: relative;
  top: 1.5em;
  text-decoration: none;
  background: #8bc34a;
  color: #fff;
  margin-left: 60px;
  margin: auto;
  padding: .8em 4em;
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  border-radius: 25px;
  -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

#contBtn:hover {
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.fa-plus{
  color: #20a8d8;
  border-color: #20a8d8;
  }
  .fa-plus:hover{
  cursor: pointer;
  }
.fa-minus{
    color: #ed4141;
    border-color: #ef3939;
    }
.fa-minus:hover{
      cursor: pointer;
      }
.address-radios{
  cursor: pointer;
}

.address-type{
  color: #20a8d8;
  border-color: #20a8d8;
}

.new-add{
  margin-bottom: 176px;
  margin-top: 20px;
}
/* end of success card */
