.table-bordered thead th,
.table-bordered thead td {
  background-color: #2f353a;
  border-bottom-width: 2px;
  font-weight: unset;
  color: white;
  align-items: center;
}

.search-color {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fbc108;
}

.mt-4,
.my-4 {
  margin-top: -0.5rem !important;
}

.user-table .table-responsive {
  height: 265px;
}

.justify {
  justify-content: flex-end;
}

.padding-remove {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.top-delete-message {
  display: flex;
  justify-content: center;
  color: rgb(225, 82, 75);
  padding: 10px;
}
