.container {
   max-width: 1320px !important;
   padding: 0px;
}

.padding-bottom-10 {
   padding: 10px 1px 25px 0px;
}

.video {
   width: 100%;
   height: 500px;
}

.video-series-image-thumbnail {
   width: 100%;
   height: 130px;
}

/* DISPLAY */
.flexCenter {
   display: flex;
   justify-content: center;
   align-items: center;
}

.flexCenterNull {
   display: flex;
   justify-content: center;
}

.flexSBNull {
   display: flex;
   justify-content: space-between;
}

.flexCol {
   display: flex;
   flex-direction: column;
}

/* paddings/margins */
.p-0 {
   padding: 0px;
}

.pl-0 {
   padding-left: 0px;
}

.pb-5 {
   padding-bottom: 5px !important;
}

.pb-10 {
   padding-bottom: 10px !important;
}

.pb-15 {
   padding-bottom: 15px !important;
}

.txtA-l {
   text-align: start;
}