.icon-bell {
  font-size: 20px;
  color: #fbc108;
}

.userName {
  color: white;
}

.app-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  z-index: 1020;
  color: #23282c;
  background: #282828;
  border-top: 1px solid #282828;
  flex: 0 0 60px;
  -ms-flex: 0 0 50px;
}

.banner {
  height: 750px;
}

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #e91e63;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 30px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 30px;
  color: black;
}

.footer-part {
  background-color: #282828;
}

.text-color {
  color: white;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.rowB {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 10px;
}

.image {
  position: relative;
  width: 100%;
}

.text-content {
  position: absolute;
  color: white;
  top: 75px;
  left: 0;
  width: 100%;
}

.thumbnail-text1 {
  position: absolute;
  font-size: 150%;
  color: white;
  top: 250px;

  width: 100%;
}

.title-color {
  color: #fbc108;
}

.thumbnail-text3 {
  position: absolute;
  font-size: 1.7rem;
  color: white;
  top: 370px;
  width: 100%;
}

.search-content {
  position: absolute;
  color: white;
  top: 80%;
  height: 10%;
  width: 100%;
}

.search-content .form-control {
  padding: 0.7rem 0.75rem;
}

.items {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 10px 0px 16px 0px;
  vertical-align: top;
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  height: 230px;
  padding: 5%;
  cursor: pointer;
  text-align: -webkit-center !important;
}

.top {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
  z-index: 0;
  height: 100%;
  margin-bottom: 6%;
}
.product-data {
  padding-top: 75px;
  background-color: #f6f6f6;
}

.cat-sidebar {
  border-left: 1px solid #d9d4cf;
  border-bottom: 1px solid #d9d4cf;
}

.product-search {
  color: #23282c;
  background-color: #ffc107;
  border-color: #ffc107;
  padding: 0.375rem 0.75rem;
}

.catName:hover {
  font-size: 150%;
  color: #fbc108;
}
.cat-results {
  width: 100%;
  margin-right: 4px;
}

.cat-wrapper {
  display: flex;
  background-color: white;
  width: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
}
.navB,
.navB a,
.navB ul,
.navB li,
.navB div,
.navB form,
.navB input {
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  border: none;
  outline: none;
}

.navB a {
  text-decoration: none;
}

.navB li {
  list-style: none;
}
.filter-header {
  padding: 10px 20px;
  border-top: 1px solid #d9d4cf;
  color: #8d8782;
  position: relative;
}

.navB {
  display: block;
  position: relative;
  cursor: default;
  z-index: 500;
}
.btn-link {
  cursor: pointer;
}

.navB > li {
  display: block;
  float: left;
}
.filter-header-title {
  color: #23282c;
  font-size: 0.875rem;
  margin: 0;
  line-height: 30px;
  padding-right: 30px;
  cursor: pointer;
  text-transform: uppercase;
}

.filter-header-count {
  color: #8d8782;
  border-radius: 50%;
  border: 1px solid #beb6ae;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 29px;
  text-align: center;
  float: right;
  top: 10px;
}

.navB > li > a {
  position: relative;
  display: block;
  z-index: 510;
  height: 54px;
  padding: 5px 30px;
  line-height: 54px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: black;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
  background: white;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.navB > li:hover > a {
  background: #fbc108;
}

.navB > li:first-child > a {
  border-radius: 3px 0 0 3px;
  border-left: none;
}

.cat-utility-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #d9d5cf;
  border-top: 1px solid #d9d5cf;
  max-height: -webkit-fill-available;
}

.cat-utility-count {
  width: 100%;
  border-right: 1px solid #d9d4cf;
  line-height: 1.5em;
  padding: 7px 20px;
}

.cat-utility-sort {
  height: 33px;
  border: 0;
  width: -webkit-fill-available;
  cursor: pointer;
  background-color: white;
}

.navB > li > div {
  position: absolute;
  display: block;
  width: 100%;
  top: 55px;
  left: 0;

  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  background: #fff;
  border-radius: 0 0 3px 3px;
  border: 2px solid lightgray;
  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
}
.navB > li:hover > div {
  opacity: 1;
  visibility: visible;
  overflow: visible;
}

.navB .nav-column {
  float: left;
  width: 20%;
  padding: 1.5%;
  color: #fff;
}

.radio-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;
  font-size: 1rem;
  color: #23282d;
}

.radio-cursor {
  cursor: pointer;
}

.navB .nav-column h3 {
  margin: 20px 0 10px 0;
  line-height: 18px;

  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #372f2b;
  text-transform: uppercase;
}

.navB .nav-column h3.orange {
  color: #ff722b;
}

.navB .nav-column li a {
  display: block;
  line-height: 26px;

  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #888888;
}

.navB .nav-column li a:hover {
  color: #666666;
}
.cat-results-header {
  padding: 20px;
  border-bottom: 1px solid #d9d4cf;
  background-color: white;
}

.rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.product-thumb-labelS {
  /* font-size: .825rem; */
  margin-right: 138px;
}

.product-thumb-titleS {
  float: left;
  font-family: initial;
  font-size: 17px;
  margin: 0 0;
  width: 219px;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-overflow: ellipsis;
}
.product-thumb-titleH {
  float: left;
  font-family: initial;
  font-size: 17px;
  margin: 0 0;
  width: 219px;
  overflow: hidden;
  white-space: nowrap;
  height: 32px;
  text-overflow: ellipsis;
  padding-left: 10px;
  margin-bottom: 5px;
}

.product-thumb-priceS {
  font-size: 14px;
  text-align: left;
}

.cat-sidebar input.cat-search {
  display: flex;
  border: 0;
  font-style: italic;
  height: 52px;
  width: 100%;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org…2C12%2C5.51%2C5.51%2C0%2C0%2C1%2C1%2C6.5Z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
  padding-left: 46px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 20px 18px;
}

.cat-search-form {
  border-top: 1px solid #d9d4cf;
}

.product-thumb-div {
  height: 300px;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0px 0px;
  border-bottom: 1px solid #d9d4cf;
  border-right: 1px solid #d9d4cf;
}

.product-thumb-div:hover {
  height: 300px;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0px 0px;
  border-bottom: 1px solid #d9d4cf;
  border-right: 1px solid #d9d4cf;
  border: 1px solid #fbc108;
}

.text-family {
  font-family: "Open Sans,Arial,Helvetica,sans-serif";
}

.loader {
  font-size: 1.875rem;
  padding-left: 48%;
}

.resul-not-found {
  margin-left: 37%;
}

.a-cat {
  color: #372f2b !important;
  cursor: pointer;
}
.discount-percentage {
  color: red;
  margin-right: 50px;
}

.clear-All {
  float: right;
  margin-right: 5%;
  margin-top: 2%;
  cursor: pointer;
}

.product-priceS {
  margin-top: 10%;
  font-size: 17px;
}

.product-newpriceS {
  margin-right: 136px;
  font-size: 17px;
}
.product-by {
  float: left;
  margin-left: 10px;
}
.product-brand {
  margin-right: 50px;
}
.strike-priceS {
  margin-left: 10px;
}

.navbar-search {
  margin-top: 1rem;
  box-shadow: 0 0 3px 0 rgba(50, 50, 50, 0.6);
  z-index: 99;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .navbar-search {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 425px) {
  .navbar-search {
    margin-top: 2.6rem;
  }
}

@media only screen and (max-width: 375px) {
  .navbar-search {
    margin-top: 2.8rem;
  }
}

.main-search-input {
  width: 80%;
  max-width: 688px;
  height: 40px;
  border: none;
  background-color: #ffffff;
  border-radius: 127px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  padding-left: 5px;
}

.main-search-button {
  width: 20%;
  max-width: 150px;
  height: 40px;
  border: solid 1px #000000;
  background-color: #000000;
  background-image: url(../../../assets/img/search.svg);
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.selected-categories {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 50px;
  border-radius: 16px;
}

.product-area {
  background-color: white;
  margin-bottom: 30px;
  padding-bottom: 30px;
  min-height: 300px;
  border-radius: 21px;
}

.category-dropdown-area {
  min-height: 100px;
  background-color: white;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 21px;
  padding: 0px 20px;
}

.category-dropdown {
  width: 400px;
  height: 34px;
  border-radius: 2px;
  border: none;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 15px;
  color: #6d6d6d;
  padding-left: 20px;
  padding-right: 20px;
}

.main-category-label {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #777676;
  vertical-align: middle;
}

.filter-tag {
  border-radius: 2px;
  border: solid 0.3px #707070;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.filter-tag-close {
  width: 8px;
  height: 9px;
  object-fit: scale-down;
  border: none;
  background-image: url(../../../assets/img/tag-close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
}

.filter-tag-subtitle {
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8f8f8f;
  height: 12px;
}

.filter-tag-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.filter-tag-text-area {
  margin-right: 40px;
}

.expand-button {
  color: #777676;
  cursor: pointer;
}

.browse-categories {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 10px;
  border-top: 1px solid #f6f6f6;
}
.category-box-wrapper {
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
}

.filter-list-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.main-div-center {
  justify-content: center;
}
/** media query for unsetting content align on small screens */
@media only screen and (max-width: 768px) {
  .main-div-center {
    justify-content: unset;
  }
}
