a {
  text-decoration: none;
}

.purchase {
  /* border-top:11px solid #E85700; */
  /* padding:89px 0 0; */
  text-align: center;
  font-family: "Lato";
}
.purchase h1 {
  font-size: 26px;
  letter-spacing: 3px;
  line-height: 40px;
  text-transform: uppercase;
}
.purchase p {
  font-size: 26px;
  line-height: 45px;
  max-width: 750px;
  margin: 0 auto;
}
.purchase-trigger {
  margin-top: 50px;
  border: 1px solid #e85700;
  border-radius: 50px;
  font-size: 26px;
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.purchase-trigger * {
  -webkit-transition: 0.3s cubic-bezier(0.54, 1.41, 0.54, 0.87);
  -moz-transition: 0.3s cubic-bezier(0.54, 1.41, 0.54, 0.87);
  -o-transition: 0.3s cubic-bezier(0.54, 1.41, 0.54, 0.87);
  transition: 0.3s cubic-bezier(0.54, 1.41, 0.54, 0.87);

  -webkit-transition: 0.4s cubic-bezier(0.165, 0.925, 0.51, 1.005);
  -moz-transition: 0.4s cubic-bezier(0.165, 0.925, 0.51, 1.005);
  -o-transition: 0.4s cubic-bezier(0.165, 0.925, 0.51, 1.005);
  transition: 0.4s cubic-bezier(0.165, 0.925, 0.51, 1.005);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.purchase-trigger span {
  padding: 17px 80px;
  color: #e85700;
  width: 246px;
  display: inline-block;
}
.purchase-trigger .bubble {
  position: absolute;
  background: #e85700;
  top: 0;
  border-radius: 50px;
  left: 0;
  height: 74px;
  width: 246px;
  z-index: -1;
}
.purchase-trigger.right .bubble {
  margin-left: 246px;
}

.purchase-trigger:not(.right) .annual {
  color: #fff;
}
.purchase-trigger.right .monthly {
  color: #fff;
}

.purchase ul {
  display: table;
  margin: 70px auto 0;
  width: 100%;
  max-width: 1100px;
}
.purchase li {
  display: table-cell;
  width: 33.333%;
  padding: 43px 0 0;
  -webkit-transition: 0;
  -webkit-transition: all 0.25s, color 0s !important;
  -moz-transition: all 0.25s, color 0s !important;
  -o-transition: all 0.25s, color 0s !important;
  transition: all 0.25s, color 0s !important;
  position: relative;
  z-index: 0;
  border: 1px solid #bbb;
}

.purchase li:first-child {
  position: relative;
  right: -1px;
}
.purchase li:last-child {
  position: relative;
  left: -1px;
}

.purchase li:hover {
  box-shadow: 0 13px 48px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: rgb(0, 0, 0) !important;
  background: #f9f402;
  border-color: #f9f402;
  -webkit-transform: scale(1.05);
}

.purchase li * {
  -webkit-transition: 0 !important;
  -moz-transition: 0 !important;
  -o-transition: 0 !important;
  transition: 0 !important;
}

.purchase li:hover * {
  color: #2f353a;
  border-color: rgba(255, 255, 255, 0.27) !important;
}

.purchase li strong {
  font-size: 19px;
  text-transform: uppercase;
  color: #2f3740;
  letter-spacing: 2.4px;
  line-height: 45px;
  font-weight: 400;
  margin-bottom: 25px;
  display: inline-block;
}

.purchase ul .purchase-description {
  display: block;
  font-size: 19px;
  line-height: 30px;
}

.purchase .purchase-price {
  font-size: 30px;
  letter-spacing: 2px;
  padding-top: 20px;
  display: block;
  font-weight: 400;
  padding-bottom: 12px;
}
.purchase .purchase-button {
  text-transform: uppercase;
  font-size: 19px;
  color: #2f3740;
  text-decoration: none;
  line-height: 1;
  padding: 28px 0 24px;
  border-top: 1px solid #bbb;
  width: 100%;
  display: inline-block;
  margin-top: 6px;
  display: block;
}
.purchase li:hover .purchase-button {
  -webkit-transition: 0;
  -moz-transition: 0;
  -o-transition: 0;
  transition: 0;
}

.purchase ul a:hover {
  color: #e85700;
}

.purchase ul a,
.purchase ul a:hover .purchase-price,
.purchase ul a:hover .purchase-description {
  color: #6c6c6c;
}

/* success card css */
#card {
  position: relative;
  top: 110px;
  width: 320px;
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
}

#upper-side {
  padding: 2em;
  background-color: #ffc107;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

#checkmark {
  font-weight: lighter;
  fill: #fff;
  margin: -3.5em auto auto 20px;
}

#status {
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1em;
  margin-top: -0.2em;
  margin-bottom: 0;
}

#lower-side {
  padding: 2em 2em 5em 2em;
  background: #e4d9d9;
  display: block;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#message {
  margin-top: -0.5em;
  color: #757575;
  letter-spacing: 1px;
}

#contBtn {
  position: relative;
  top: 1.5em;
  text-decoration: none;
  background: #ffc107;
  color: #fff;
  margin: auto;
  padding: 0.8em 3em;
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  border-radius: 25px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#contBtn:hover {
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.custom-section {
  padding: 50px;
}
.select-plan-heading {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 2px;
  color: #23282c;
}

.select-plan-p {
  font-size: 12px;
  font-weight: 500;
  color: #73818f;
}

.cards-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

@media (max-width: 1537px) {
  .cards-container {
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 10px;
  }
}

.custom-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  min-width: 147px;
}

.custom-card-header {
  border-bottom: 0.3px solid #c8ced3;
  padding: 25px 15px;
}

.custom-card-body {
  padding: 15px;
}

.custom-card-header-h {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
}

.custom-card-price-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0px;
}

.custom-card-dollor-sign {
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: -20px;
  color: #73818f;
  font-weight: 600;
}

.custom-card-amount {
  font-size: 30px;
  margin: 0px 5px 0px 5px;
  font-weight: 600;
}

.custom-card-duration {
  font-size: 12px;
  margin-bottom: -10px;
  color: #73818f;
  font-weight: 600;
}

.features-container {
  margin: 20px 0px;
}

.doue-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}

.single-feature {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;
}

.single-feature-right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;
  float: right;
}

.single-feature-h {
  margin-bottom: 0px;
  margin-left: 5px;
  color: #5a5f7d;
  font-size: 13px;
}

.check-green {
  color: #20c997;
  padding: 5px;
}
.check-red {
  color: red;
  padding: 5px;
}

.subscription-button {
  background-color: #f8ca2e;
  border: 0px;
  margin: 10px 0px;
  width: 110px;
  height: 30px;
}

.subscribed {
  background-color: #fff1c2;
}
