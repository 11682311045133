.input-group-text {
  padding: 0.6rem 0.75rem;
}

.errvalidcolor {
  color: "red";
  margin-top: "-3%";
}

a:not([href]):not([tabindex]) {
  color: #00aced;
  text-decoration: none;
}

/* div.skylight-dialog{
    height : 400px;
    overflow:scroll;
} */

.register-background {
  background: #eeac1a;
  /* fallback for old browsers */
  background-image: url(../../../assets/img/signin-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 95% 15%;
  & .container {
    max-width: 1400px;
    max-height: 520px;
    width: 100%;
  }
}
.register-main-wrapper {
  height: 850px;
  width: 100%;
  background-color: #fbc218;
}
.kep-login-facebook.metro {
  background-color: #4c69ba;
  text-transform: lowercase;
  display: block;
  color: rgb(255, 255, 255);
  width: 170px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  font-family: Roboto;
  margin-left: -20px;
}

.google_btn {
  display: inline-block !important;
  background: rgb(209, 72, 54);
  color: rgb(255, 255, 255);
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: bold;
  font-family: Roboto;
}

.facebook_btn {
  border-radius: 4px;
}

.twitter-btn {
  background-color: #4c69ba;
  text-transform: lowercase;
  border-radius: 4px;
  display: inline-block !important;
  color: rgb(255, 255, 255);
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  font-family: Roboto;
  border: 0px;
}

.register-tab-text-register {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin-tab-text-register {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.register-button {
  width: 240px;
  height: 52px;
  border: solid 1px #000000;
  background-color: #000000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
}

.title-register {
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  font-family: Roboto;
  color: #000000;
  display: flex;
  justify-content: center;
}

.the-door-is-here {
  width: 447px;
  height: 17px;
  font-family: Roboto;
  font-size: 48px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -2.56px;
  text-align: left;
  color: #000000;
  margin-top: 100px;
}

.and-the-key {
  width: 442px;
  height: 40px;
  font-family: Roboto;
  font-size: 64px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: -3.68px;
  text-align: left;
  color: #000000;
}

.in-your-hand {
  width: 541px;
  height: 116px;
  font-family: Roboto;
  font-size: 70px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -3.88px;
  text-align: left;
  color: #000000;
  margin-top: 50px;
}

.create-your-own-key {
  width: 489px;
  height: 34px;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
@media only screen and (max-width: 1024px) {
  .register-background {
    & .container {
      max-height: 450px;
    }
  }
}
@media (max-width: 991px) {
  .hide-on-mobile {
    display: none;
  }
}
.fixed-header {
  position: fixed;
  top: 0;
}
