.janus-btn {
  height: 30px;
}

.streaming-card-height {
  height: 25em;
}

.kurento-video-style {
  width: 100%;
  height: 23em;
}

/* chat conversation css */

.header {
  font-size: 26px;
  margin: 0 auto;
  text-align: center;
  color: #9f6905;
  font-weight: lighter;
  background-color: #f0f0f0;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #9f6905;
}
.chat-container {
  width: auto;
  cursor: default;
  margin: -20px auto;
  max-height: 350px;
  overflow-y: scroll;
}
.chat-container::-webkit-scrollbar {
  width: 3px;
  max-width: 3px;
  height: auto;
  max-height: 8px;
}
.chat-container::-webkit-scrollbar-thumb {
  background: #f0f0f0;
  border-radius: 5px;
  max-width: 3px;
}
.chat-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 5px;
}
.Area {
  background-color: rgba(240, 240, 240, 0.2);
  display: table;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.L {
  float: left;
}
.chat-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #f0f0ff;
  padding: 5px;
}
.chat-img:hover {
  -moz-box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  -webkit-box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  box-shadow: 0 5px 5px rgba(223, 120, 8, 1);
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
  cursor: pointer;
}
.R {
  float: right;
}
.text {
  color: #a4a4a4;
  font-family: tahoma;
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;
  width: 300px;
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px;
}
.Area textarea {
  font-size: 12px;
  width: 90%;
  max-width: 90%;
  min-width: 90%;
  padding: 5%;
  border-radius: 5px;
  border: 1px solid #f0f0f1;
  max-height: 50px;
  height: 50px;
  min-height: 50px;
  background-color: #333;
  color: #fff;
  outline: none;
  border: 1px solid transparent;
  resize: none;
}
.Area textarea:focus {
  color: #333;
  border: 1px solid #ccc;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
  background-color: #fff;
}
.Area .note {
  color: #9f6905;
  font-size: 10px;
}
.R .tooltip {
  font-size: 10px;
  position: absolute;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #9f6905;
  margin-left: 70px;
  margin-top: -70px;
  display: none;
  color: #545454;
}
.R .tooltip:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 1px;
  border: 5px solid transparent;
  border-right-color: #9f6905;
  margin-top: 10px;
  margin-left: -17px;
}
.R:hover .tooltip {
  display: block;
}

.L .tooltip {
  font-size: 10px;
  position: absolute;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #9f6905;
  margin-left: 70px;
  margin-top: -70px;
  display: none;
  color: #545454;
}
.L .tooltip:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 1px;
  border: 5px solid transparent;
  border-right-color: #9f6905;
  margin-top: 10px;
  margin-left: -17px;
}
.L:hover .tooltip {
  display: block;
}
a {
  text-decoration: none;
}

.Area input[type="button"] {
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f0f0f1;
  background-color: #333;
  color: #fff;
  outline: none;
  border: 1px solid transparent;
  float: left;
}
.Area input[type="button"]:hover {
  color: #fff;
  border: 1px solid #ccc;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
  background-color: #9f6905;
}
.validation {
  float: left;
  background-color: #ccc;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  line-height: 14px;
  height: 0px;
  margin-left: 5px;
  display: none;
}
br {
  clear: both;
  height: 20px;
}
.chat-placeholder {
  width: 100%;
}

.sender-name {
  font-weight: bold;
  color: #6c6a6a;
}

.mar-top-1 {
  margin-top: 1%;
}

/* success card css */
#card {
  position: relative;
  top: 110px;
  width: 320px;
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
}

#upper-side {
  padding: 2em;
  background-color: #ffc107;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

#checkmark {
  font-weight: lighter;
  fill: #fff;
  margin: -3.5em auto auto 20px;
}

#stat {
  font-weight: lighter;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  font-size: 1em;
  margin-top: -0.2em;
  margin-bottom: 0;
}

#lower-side {
  padding: 2em 2em 5em 2em;
  background: #e4d9d9;
  display: block;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#message {
  margin-top: -0.5em;
  color: #757575;
  letter-spacing: 1px;
}

#contBtn {
  position: relative;
  top: 1.5em;
  text-decoration: none;
  background: #ffc107;
  color: #fff;
  margin: auto;
  padding: 0.8em 3em;
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  border-radius: 25px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#contBtn:hover {
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
/* end of success card */

.media-box1 > video {
  max-height: -webkit-fill-available !important;
}

@-moz-document url-prefix() {
  .media-box1 > video {
    width: 371px;
  }
}

.media-box1 > video {
  width: 100%;
  height: 23em;
}

#Popover1 {
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .card-body {
    &.stream-rtc-body {
      video {
        width: 390px;
        height: auto;
      }
    }
  }
}
