hr{
    background-color : rgb(216, 221, 226);
}

.title{
    color:darkgray
}
.onerror{
    color: red;
  }
  
  .image-padding {
    padding-right: 2%;
    cursor: pointer;
  }
  
  .imag:hover .middle {
    opacity: 1;
  }
  
  
  .container {
  position: relative;
  width: 100%;
  display: inline-block;
  }
  
  .image {
  
  width: 100%;
  height: auto;
  display: inline-block;
  flex-wrap: wrap;
  padding: 3px 4px;
  
  }
  
  .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  cursor: pointer;
  }
  
  .container:hover .overlay {
  opacity: 1;
  }
  
  .text {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  }
  
  .category-padding {
    padding-left: 5%;
    padding-right:10%;
  }
  
  .close {
    position: absolute;
    right: 20px;
    top: 4px;
    width: 12px;
    height: 11px;
    opacity: 1 !important;
    background-color: white;
  }
  
  .close:before, .close:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .ellipsis {
    overflow: hidden;
    /* height: 200px; */
    line-height: 25px;
    margin: 20px;
    /* border: 5px solid #AAA; */
 }

.ellipsis:before {
    content:"";
    float: left;
    width: 5px; height: 200px; }

.ellipsis > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px; }        

.ellipsis:after {
    content: "\02026";  

    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    float: right; position: relative;
    top: -25px; left: 100%; 
    width: 3em; margin-left: -3em;
    padding-right: 5px;

    text-align: right;

    background: -webkit-gradient(linear, left top, right top,
        from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white));
    background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);           
    background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white); }
  