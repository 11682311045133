/* Footer container */
.footer-container {
  background-color: black;
  /* Light green background */
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #EEAC1A;
}

/* Address */
.footer-address p {
  margin: 0;
  font-size: 14px;
  color: #EEAC1A;
}

/* Footer info */
.footer-info p {
  margin: 10px 0;
  font-size: 14px;
  color: #EEAC1A;
}

.footer-info p a:hover {
  color: #EEAC1A;
}

.footer-link1 {
  color: #ead5a9;
  /* Blue link color */
  text-decoration: underline;
}

.footer-link1:hover {
  color: #EEAC1A;
}

/* Social Media Section */
.footer-social {
  margin-top: 10px;
}

.social-icon {
  margin: 0 10px;
  display: inline-block;
}

.social-icon img {
  width: 24px;
  height: 24px;
}

.social-icon img:hover {
  color:#EEAC1A;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    text-align: center;
  }

  .footer-info p,
  .footer-address p {
    font-size: 12px;
  }

  .social-icon img {
    width: 20px;
    height: 20px;
  }
}