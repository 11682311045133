/* BlogCard.css */

.blog-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100vw;
  padding: 3vw 5vw;
  background-color: #f3f4f6;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.blog-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-left: 5px solid #EEAC1A;
}

/* Image styling */
.blog-card-thumbnail {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
}

/* Content styling */
.blog-card-content {
  padding: 1.5rem;
}

.blog-card-date {
  font-size: 0.875rem;
  color: #6B7280;
  margin: 0 0 0.5rem;
}

.blog-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2D3748;
  margin: 0 0 0.5rem;
}

.blog-card-author {
  font-size: 0.875rem;
  color: #4B5563;
  margin: 0 0 1rem;
  font-style: italic;
}

.blog-card-description {
  font-size: 1rem;
  color: #4B5563;
  margin: 0 0 1rem;
  line-height: 1.6;
}

.blog-card-link {
  font-size: 1rem;
  color: #EEAC1A;
  font-weight: bold;
  text-decoration: none;
}

.blog-card-link:hover {
  text-decoration: underline;
}

/* Responsive adjustments for larger screens */
@media (min-width: 1024px) {
  .blog-card {
    flex-direction: row;
  }

  .blog-card-thumbnail {
    width: 50%; /* Image takes half the space */
    height: auto; /* Maintain aspect ratio */
  }

  .blog-card-content {
    width: 50%; /* Content takes half the space */
    padding: 2rem;
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
  .blog-container {
    width: 100%;
    padding: 0.5rem;
  }

  .blog-card {
    margin: 0 auto;
  }
}
