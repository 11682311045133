.public-DraftStyleDefault-block {
    /* margin: 1em 0;
    border: 2px solid #e4e7ea;
    border-radius: 3px;
    height: auto; */

  background-color: #fffef7;
  margin:auto;
  height:100px;
  overflow-y: scroll;
  width:  100%;
  border: 2px solid #e4e7ea;
  border-radius: 3px;
  padding: 1%;
}

.rdw-editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 3px;
    border: 7px solid #e4e7ea;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background: white;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.required {
  color: red;
}