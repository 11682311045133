.nav-link {
  color: rgb(255, 255, 255) !important;
  min-width: 100px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.sidebar {
  background-color: #000000;
}

.user-main {
  background-color: #ffffff !important;
  padding-right: 150px;
  margin-left: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}
.scrollbar-container.sidebar-nav .nav-item .nav-link {
  text-align: left;
  font-size: 16px;
  font-weight: normal;
}

@media (max-width: 1024px) {
  .user-main {
    padding-right: 50px;
  }
}
@media (min-width: 992px) {
  .sidebar-fixed .app-header + .app-body .sidebar {
    height: calc(100vh - 55px);
    overflow: scroll !important;
  }
}
@media (max-width: 767.98px) {
  .app-header .nav-item .nav-link {
    min-width: 40px;
  }
  .app-header .navbar-brand.no-sidebar-reposition {
    left: 38%;
  }
  .nav-link.profile-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
  }
  .sidebar-fixed .app-header + .app-body .sidebar {
    overflow: scroll !important;
  }
}
@media (max-width: 765px) {
  .none {
    display: none;
  }
  .first-div {
    margin-top: 30px !important;
  }
  .user-main {
    padding-right: 10px !important;
    margin-left: 12px !important;
  }
  .user-sidebar .list-group {
    flex-direction: row !important;
  }
  .user-sidebar {
    padding-left: 0px !important;
    padding-top: 30px !important;
  }
  .user-sidebar .direction {
    flex-direction: row !important;
    padding-left: 9px;
    overflow-x: scroll;
    max-width: 98%;
  }
  .button-color-user {
    background-color: #f6c803;
    min-width: fit-content;
    width: 100%;
    padding: 15px 20px !important;
    border-color: #eabf00 !important;
    color: white;
    border-radius: 1px !important;
    padding: 5px 15px;
  }
  .scrollbar-container.sidebar-nav {
    margin-bottom: 100px;
  }
}

.user-sidebar {
  flex-wrap: unset !important;
  flex-direction: column;
  padding-left: 150px;
  padding-top: 50px;
}

.active-link {
  color: #000000 !important;
  background-color: #ffcf00 !important;
}

@media only screen and (max-width: 1024px) {
  .user-sidebar {
    padding-left: 50px;
  }
}
