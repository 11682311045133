@media only screen and (max-width: 320px) {
  .navbar-brand-full {
    margin-right: 220px !important;
  }
  .navbar-brand-minimized {
    margin-right: 220px !important;
  }
}

@media only screen and (max-width: 375px) {
  .navbar-brand-full {
    margin-right: 220px !important;
  }
  .navbar-brand-minimized {
    margin-right: 220px !important;
  }
}

@media only screen and (max-width: 425px) {
  .navbar-brand-full {
    margin-right: 220px !important;
  }
  .navbar-brand-minimized {
    margin-right: 220px !important;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-brand-full {
    margin-right: 550px;
  }
  .navbar-brand-minimized {
    margin-right: 550px;
  }
}

.nav-link {
  color: rgb(255, 255, 255) !important;
  min-width: 100px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.navbar-register {
  width: 135px;
  height: 40px;
  border-radius: 8px;
  background-color: #eeac1a;
  padding-top: 10px;
}

.navbar-register-text {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000000 !important;
}

.profle-dropdown-text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.profile-dropdown {
  width: 219px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ffffff;
  background-color: #ffffff;
  /* padding-left: 15px; */
  /* padding-right: 15px; */
}

.show-on-non-mobile {
  display: none;
}
.header-logo {
  width: 70px;
}

@media only screen and (max-width: 991px) {
  .hide-on-mobile {
    display: none;
  }
}

@media only screen and (min-width: 991px) {
  .hide-on-non-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .header-logo {
    display: none !important;
  }
}
