/* Container */
.media-player-container {
  height: auto;
  width: 100%;
  background: linear-gradient(135deg, #1a1c2e 0%, #2d3748 100%);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Wrapper */
.media-player-wrapper {
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
}

/* Title */
.media-player-title {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 2rem;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Video Container */
.video-container {
  position: relative;
  height: auto;
  width: 100%;
  aspect-ratio: 16/9;
  background: #000;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
}

/* Video */
.media-player-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Controls */
.media-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
  padding: 2rem 1.5rem 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.media-controls.visible {
  opacity: 1;
}

/* Show controls on hover */
.video-container:hover .media-controls {
  opacity: 1;
}

/* Progress Container */
.progress-container {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}

/* Progress Bar */
.progress-bar {
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  transition: height 0.2s ease;
}

.progress-container:hover .progress-bar {
  height: 6px;
}

.progress-fill {
  height: 100%;
  background: #4c6793;
  border-radius: 2px;
  position: relative;
  transition: background-color 0.2s ease;
}

.progress-fill:hover {
  background: #5f82b8;
}

.progress-handle {
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.progress-container:hover .progress-handle {
  opacity: 1;
}

/* Control Buttons */
.control-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
}

.controls-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.controls-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Button Styling */
.control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  color: white;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  min-width: 40px;
  height: 40px;
}

.control-button:hover {
  background-color: rgba(0, 0, 0, 1);
  transform: scale(1.05);
}

/* Volume Container */
.volume-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.volume-slider {
  width: 100px;
  display: flex;
  align-items: center;
}

.volume-slider input[type="range"] {
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  cursor: pointer;
}

.volume-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.volume-slider input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

/* Time Display */
.time-display {
  color: white;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  min-width: 90px;
}

/* Description */
.media-player-description {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  margin-top: 2rem;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.media-player-description h3 {
  font-size: 1.8rem;
  color: #ffffff;
  margin-bottom: 1rem;
  font-weight: 600;
}

.media-player-description p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.7;
}

/* Loading Animation */
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #3b82f6;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Fullscreen Styles */
.media-player-wrapper.fullscreen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.fullscreen .video-container {
  height: 100vh;
  border-radius: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .media-player-container {
    padding: 1rem;
  }

  .media-player-wrapper {
    padding: 1rem;
  }

  .media-player-title {
    font-size: 1.8rem;
  }

  .controls-left {
    gap: 0.5rem;
  }

  .controls-right {
    gap: 0.5rem;
  }

  .control-button {
    min-width: 36px;
    height: 36px;
    padding: 0.4rem;
  }

  .volume-slider {
    width: 70px;
  }

  .time-display {
    font-size: 0.8rem;
    min-width: 80px;
  }

  .media-player-description h3 {
    font-size: 1.5rem;
  }

  .media-player-description p {
    font-size: 1rem;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .media-player-container {
    padding: 0.5rem;
  }

  .media-player-wrapper {
    padding: 0.5rem;
  }

  .media-player-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .media-controls {
    padding: 1rem;
  }

  .progress-container {
    padding: 0 0.5rem;
  }

  .control-button {
    min-width: 32px;
    height: 32px;
    padding: 0.3rem;
  }

  .controls-left {
    gap: 0.3rem;
  }

  .controls-right {
    gap: 0.3rem;
  }

  .volume-slider {
    width: 50px;
  }

  .time-display {
    font-size: 0.7rem;
    min-width: 70px;
  }

  .media-player-description {
    padding: 1rem;
  }

  .media-player-description h3 {
    font-size: 1.3rem;
  }

  .media-player-description p {
    font-size: 0.9rem;
  }
}

/* Update the fullscreen styles */
.video-container:fullscreen {
  width: 97vw;
  height: auto;
  background: black;
  padding: 0;
  margin: 0;
}

.video-container:fullscreen .media-player-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* For Firefox */
.video-container:-moz-full-screen {
  width: 97vw;
  height: auto;
}

/* For Safari */
.video-container:-webkit-full-screen {
  width: 97vw;
  height: auto;
}