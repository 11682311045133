

.content-button {
  width: 200px;
  background-color: rgb(228, 231, 234);
  margin: 5px;
  color: black;
  cursor: pointer;
}
.content-button:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.tf-top {
  display: flex;
  justify-content: center;  /* Centers content horizontally */
  align-items: center;      /* Centers content vertically */
}


h3{
  font-size: 30px;
  color: black;
  font-weight:500;
  justify-self: center;
}

.tf-top h1{
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tranlationFieldbutton {
  border: 1px solid black;
  background-color: yellow;
}


.back-button {
  font-size: 24px;
  padding: 0px 15px; /* Adjusted padding for a better shape */
  background-color: black;
  color: white; /* Set text color to white */
  border: none; /* Remove any border */
  border-radius: 50%; /* Make the button round */
  cursor: pointer; /* Change cursor on hover to indicate it's clickable */
  transition: background-color 0.3s ease; /* Smooth transition for the hover effect */
  margin-right: auto;
  position:static;
}

.back-button:hover {
  background-color: darkgray; /* Change color on hover */
}
