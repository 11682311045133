.coaching-process {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  min-height: 100vh;
}

.coaching-container {
  max-width: 1200px;
  margin: 0 auto;
}

.coaching-title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  color: #2d3748;
  margin-bottom: 1rem;
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

.coaching-subtitle {
  font-size: 1.25rem;
  text-align: center;
  color: #4a5568;
  margin-bottom: 3rem;
  opacity: 0;
  animation: fadeIn 0.8s ease-out 0.2s forwards;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.process-step {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
}

.process-step.visible {
  opacity: 1;
  transform: translateY(0);
}

.process-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.process-step.active {
  border: 2px solid currentColor;
}

.step-content {
  position: relative;
  z-index: 1;
}

.icon-wrapper {
  width: 64px;
  height: 64px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.2) 100%);
  backdrop-filter: blur(10px);
}

.step-icon-symbol {
  font-size: 2rem;
}

.step-heading {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 1rem;
  text-align: center;
}

.step-details {
  font-size: 1rem;
  color: #4a5568;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 1.5rem;
}

.step-index {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #e2e8f0;
  color: #2d3748;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
}

.progress-track {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #e2e8f0;
  border-radius: 0 0 1rem 1rem;
  overflow: hidden;
}

.progress-fill {
  width: 0;
  height: 100%;
  background: currentColor;
  transition: width 0.3s ease;
}

.process-step:hover .progress-fill {
  width: 100%;
}

/* Step color variations */
.process-primary {
  color: #4299e1;
}

.process-secondary {
  color: #48bb78;
}

.process-tertiary {
  color: #805ad5;
}

.process-quaternary {
  color: #ed8936;
}

.process-quinary {
  color: #ed64a6;
}

.process-senary {
  color: #63b3ed;  /* Adjust this color as desired */
}


.coaching-footer {
  text-align: center;
  margin-top: 3rem;
  opacity: 0;
  animation: fadeIn 0.8s ease-out 0.4s forwards;
}

.coaching-cta-btn {
  background: #EEAC1A;
  color: white;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(66, 153, 225, 0.25);
}

.coaching-cta-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(66, 153, 225, 0.35);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .coaching-process {
    padding: 3rem 1rem;
  }

  .coaching-title {
    font-size: 2rem;
  }

  .coaching-subtitle {
    font-size: 1.125rem;
  }

  .steps-grid {
    grid-template-columns: 1fr;
  }

  .process-step {
    padding: 1.5rem;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .coaching-process {
    background: linear-gradient(135deg, #1a202c 0%, #2d3748 100%);
  }

  .coaching-title {
    color: #f7fafc;
  }

  .coaching-subtitle {
    color: #e2e8f0;
  }

  .process-step {
    background: #2d3748;
  }

  .step-heading {
    color: #f7fafc;
  }

  .step-details {
    color: #e2e8f0;
  }

  .step-index {
    background: #4a5568;
    color: #f7fafc;
  }

  .progress-track {
    background: #4a5568;
  }
}
