/* CallToAction Component Styles */
.call-to-action {
  background-color: white;
  text-align: center;
  padding: 3vw 5vw;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 2rem;
}



.call-to-action-button:hover {
  background-color: #dba020;
  color: white;
}

.call-to-action-icons {
  display: grid;
  gap: 1rem;
  justify-items: center;
  align-items: center;
  padding: 1.5rem 0;
  margin-top: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #f3f4f6;
  height: 100%;
  grid-auto-flow: row;
  place-items: center;
}

/* Individual icon sections */
.call-to-action-icon {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 0.5rem;
}

/* Text and icon alignment */
.call-to-action-icon p {
  color: #4b5563;
  font-weight: 500;
  margin: 0;
  text-align: center;
  font-size: 1rem;
  /* 16px base size */
  line-height: 1.5;
}

/* Icon size */
.call-to-action-icon .icon {
  color: #EEAC1A;
  font-size: 2.5rem;
  /* 40px */
  margin-bottom: 0.5rem;
}

/* Mobile (up to 640px) */
@media (max-width: 640px) {
  .call-to-action-icons {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    padding: 1rem 0;
  }

  .call-to-action-icon p {
    font-size: 0.875rem;
    /* 14px */
  }

  .call-to-action-icon .icon {
    font-size: 2rem;
    /* 32px */
  }

  .call-to-action-button {
    font-size: 1rem;
    /* 16px */
    padding: 0.625rem 1.25rem;
  }
}

/* Small tablets (641px - 768px) */
@media (min-width: 641px) and (max-width: 768px) {
  .call-to-action-icons {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.875rem;
    padding: 1.25rem 0;
  }

  .call-to-action-icon p {
    font-size: 0.9375rem;
    /* 15px */
  }

  .call-to-action-icon .icon {
    font-size: 2.25rem;
    /* 36px */
  }

  .call-to-action-button {
    font-size: 1.0625rem;
    /* 17px */
    padding: 0.6875rem 1.375rem;
  }
}

/* Tablets (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1023px) {
  .call-to-action-icons {
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  .call-to-action-icon p {
    font-size: 1rem;
    /* 16px */
  }

  .call-to-action-icon .icon {
    font-size: 2.5rem;
    /* 40px */
  }

  .call-to-action-button {
    font-size: 1.125rem;
    /* 18px */
  }
}

/* Large screens (1024px and above) */
@media (min-width: 1024px) {
  .call-to-action-icons {
    grid-template-columns: repeat(6, 1fr);
    gap: 1.25rem;
    padding: 1.5rem 0;
  }

  .call-to-action-icon p {
    font-size: 1rem;
    /* 16px */
  }

  .call-to-action-icon .icon {
    font-size: 2.5rem;
    /* 40px */
  }

  .call-to-action-button {
    font-size: 1.125rem;
    /* 18px */
  }

  .call-to-action-icon {
    padding: 0.75rem;
  }
}


@media (min-width: 2560px) {
  .call-to-action-icons {
    grid-template-columns: repeat(6, 1fr);
    gap: 1.25rem;
    padding: 1.5rem 0;
  }

  .call-to-action-icon p {
    font-size: 2rem;
    /* 16px */
  }

  .call-to-action-icon .icon {
    font-size: 3.0rem;
    /* 40px */
  }

  .call-to-action-button {
    font-size: 1.125rem;
    /* 18px */
  }

  .call-to-action-icon {
    padding: 0.75rem;
  }
}