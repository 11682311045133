.confirmation-container {
  width: "100%";
  height: 625px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 200px;
  margin-top: -50px;
}

.confirmed-main-icon {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
}

.confirmation-main-title {
  font-family: Roboto;
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 6px;
}

.confirmation-sub-title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -1.2px;
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
}

.confirmation-helper-tex {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #979797;
  margin-top: 50px;
  margin-bottom: 50px;
}

.shop-more-button {
  width: 180px;
  height: 50px;
  background-color: #000000;
  border: none;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
}

@media only screen and (max-width: 425px) {
  .confirmation-container {
    margin-top: -82px !important;
    margin-left: 0 !important;
  }
}
