.row-setting {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 2px;
}
.circle-div {
  height: 50px;
  width: 50px;
  border: 1px solid rgb(245, 235, 235);
  border-radius: 50%;
}
.first-div {
  border-radius: 5px;
  margin-top: 110px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.second-div {
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.pointer-cursor {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 765px) {
  .pointer-cursor p {
    font-size: 14px;
    margin-bottom: 0px;
    padding: 1px 8px;
  }
  .app-body {
    flex-direction: column;
  }
  .user-dashboard-icons {
    width: 40px;
  }
  .pointer-cursor p {
    font-size: 11px;
  }
}
.pointer-cursor-top {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointer-cursor > img {
  margin-bottom: 0px !important;
}
.img-color {
  color: grey;
}
.botton-nav-row {
  height: 60px;
  align-items: center;
  margin: 0px !important;
}

.adress-text {
  font-size: 14px;
}
