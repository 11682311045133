/* General styles */
body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.8;
    font-size: 16px;
    background-color: #f1f5f9;
    scroll-behavior: smooth;
  }


  