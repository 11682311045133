/* styles.css or your main CSS file */
.icon-size {
    font-size: 24px;
    width: 50px;
    height: 50px;
  }

  .fixed-size-icon {
    width: 60px !important;
    height: 60px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 24px !important;
  }


  .action-icon {
    cursor: pointer;
  }
  
  .custom-tooltip {
    opacity: 1 !important;
  }
  
  .custom-tooltip .tooltip-inner {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 4px;
    padding: 6px 8px;
    font-size: 12px;
    max-width: 200px;
  }
  
  .custom-tooltip .arrow {
    display: none;
  }