@import url('https://fonts.googleapis.com/css?family=Merriweather+Sans');
/* html, body {
  font-family: 'Merriweather Sans', sans-serif;
  background: #e57373;
} */

.top-bar {
  height: 50px;
  background: white;
}

.delete {
  padding-top: 10px;
  padding-left: 5px;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.bell {
  padding: 5px;
  position: relative;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.faBell {
  color: #9FB6C3;
  font-size: 26px;
}

.notif-Head {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  color: #546E7A;
}

.pri-counter {
  background: #ef5350;
  color: #ffffff;
  border-radius: 100%;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 16px;
  width: 17px;
  height: 15px;
  font-size: 10px;
  padding-top: 1px;
  margin-left: 16%;
}

.sec-counter {
  background: #ef5350;
  color: #ffffff;
  border-radius: 100%;
  text-align: center;
  width: 19px;
  font-size: 13px;
  height: 18px;
  margin-left: 5px;
  display: inline-block;
}

.arrow {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -7px);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
}

.closed {
  visibility: hidden;
  opacity: 0;
}

.transist {
  visibility: visible;
  opacity: 1;
  color: #2336AB;
}

.notificationDropdown {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%);
  transition: visibility .3s, opacity 0.3s linear;
}

.notificationDropdown .header {
  background: #ffffff;
  width: 100%;
  height: 44px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #D5DFE4;
  font-size: 16px;
  line-height: 18px;
}

.notificationDropdown .header .container {
  width: 40%;
  margin: 0px auto;
  padding-top: 12px;
}

.notificationDropdown .items {
  background: #fff;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-y: auto;
  transition: background-color 0.5s;
}

.notificationDropdown .items .li {
  padding: 14px;
  font-size: 13px;
  line-height: 100%;
  border-bottom: 1px solid #cbd9e0;
 
  cursor: pointer;
}

.notificationDropdown .items .li:hover {
  background: #cbd9e0;
}

.new {
  background: #e1e9ed;
  transition: background-color 0.5s;
}

.notificationDropdown .items .li .pic {
  margin-right: 14px;
  margin-left: 14px;
  display: inline-block;
  float: left;
}

.notificationDropdown .items .li img {
  width: 35px;
  height: 34px;
  border-radius: 100%;
}

.notificationDropdown .items .li .content {
  color: #3d5461;
  width: 100%;
  display: inline-block;
}

.announcement{
  color: white;
  margin-left: 2%;
}

.announcement-icon{
  color: white;
}